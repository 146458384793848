import styled from "styled-components"

export const BodyCards = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 815px;
flex-wrap: wrap;
justify-content: flex-start;
align-content: space-around;
position: relative;
left: 30px;

@media screen and (max-width: 506px){
width: 506px;
height: auto;
flex-direction: column;
min-width: 506px;
}

@media screen and (max-width: 1500px){
    width: 900px;
    height: auto; 
    display: flex; 
    justify-content: center;
    align-items: center;      
    }
`
export const ImgIcn1 = styled.img` /* todos os icones*/
margin-top: 15px;
margin-left: 15px;
width: 68px;
height: 65px;
`
export const Card1 = styled.div`
width: 540px;
height: 400px;
position: relative;
`
export const Icone5 = styled.div` /*parte amarela*/
width: 96px;
height: 96px;
margin-top: 20px;
margin-left: 10px;
background-color: #FAD243;
border-radius: 16px;
`
export const Titulo5 = styled.div`
color: black;
font-size: 20px;
font-family: Josefin Sans, Arial;
font-weight: bold;
margin-top: -90px;
margin-left: 130px;
`
export const Info5 = styled.div`
color: #636363;
font-family: Josefin Sans, Arial;
font-weight: 500;
font-size: 18px;
line-height: 20px;
letter-spacing: 0.2px;
margin-left: 130px;
margin-top: 10px;
`
export const Lista1 = styled.div`
color: #636363;
width: 400px;
height: 60px;
margin-left: 110px;
font-family: Josefin Sans, Arial;
font-weight: 500;
font-size: 18px;
line-height: 20px;
letter-spacing: 0.2px;
line-height: 20px;
`
export const Card2 = styled.div`
width: 540px;
height: 400px;
position: relative;
`
export const Icone6 = styled.div`
width: 96px;
height: 96px;
margin-top: 20px;
margin-left: 10px;
background-color: #FAD243;
border-radius: 16px;
`
export const Titulo6 = styled.div`
color: black;
font-size: 20px;
font-family: Josefin Sans, Arial;
font-weight: bold;
margin-top: -90px;
margin-left: 130px;
`
export const Info6 = styled.div`
color: #636363;
font-family: Josefin Sans, Arial;
font-weight: 500;
font-size: 18px;
line-height: 20px;
letter-spacing: 0.2px;
margin-left: 130px;
margin-top: 10px;
`
export const Lista2 = styled.div`
color: #636363;
width: 400px;
height: 60px;
margin-left: 110px;
font-family: Josefin Sans, Arial;
font-weight: 500;
font-size: 18px;
line-height: 20px;
letter-spacing: 0.2px;
line-height: 20px;
`
export const Card3 = styled.div`
width: 540px;
height: 400px;
position: relative;
`
export const Icone7 = styled.div`
width: 96px;
height: 96px;
margin-top: 20px;
margin-left: 10px;
background-color: #FAD243;
border-radius: 16px;
`
export const Titulo7 = styled.div`
color: black;
font-size: 20px;
font-family: Josefin Sans, Arial;
font-weight: bold;
margin-top: -90px;
margin-left: 130px;
`
export const Info7 = styled.div`
color: #636363;
font-family: Josefin Sans, Arial;
font-weight: 500;
font-size: 18px;
line-height: 20px;
letter-spacing: 0.2px;
margin-left: 130px;
margin-top: 10px;
`
export const Lista3 = styled.div`
color: #636363;
width: 400px;
height: 60px;
margin-left: 110px;
font-family: Josefin Sans, Arial;
font-weight: 500;
font-size: 18px;
line-height: 20px;
letter-spacing: 0.2px;
line-height: 20px;
`