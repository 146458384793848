import styled from "styled-components";

export const Imgstilus = styled.img`
position: absolute;
display: inline-block;
width: 780px;
height: 670px;
@media screen and (max-width: 506px){
  width: 0% ;
}
`
export const Logo = styled.img`
position: absolute;
top: 20px;
display: inline-block;
left: 80px;
height: 80px;
width: 180px;
@media screen and (max-width: 506px){
  left: 20px ;
  top: 40px;
}
`
export const LinkC = styled.a`
text-decoration: none;
color: black;
`
export const Texto1 = styled.div`
position: absolute;
top: 190px;
padding-left: 80px;
color: #FAD243;
font-style: normal;
font-weight: bold;
font-size: 72px;
line-height: 78px;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
@media screen and (max-width: 506px){
  right: 0px;
  left: -20px;
  font-weight: 700;
  font-size: 56px;
  top: 200px ;
  z-index: -1;
  width: 400px;
}
` 
export const Texto2 = styled.div`
position: absolute;
padding-left: 285px;
top: 190px;
padding-right: 500px;
font-style: normal;
font-weight: bold;
font-size: 72px;
line-height: 78px;
font-family: Josefin Sans, Arial;
@media screen and (max-width: 506px){
  padding-right: 0px;
  left: -70px;
  font-weight: 700;
  font-size: 56px;
  top: 200px ;
  z-index: -1;
  width: 200px;
}
`
export const Texto3 = styled.div`
position: absolute;
padding-left: 80px;
top: 270px;
font-style: normal;
font-weight: bold;
font-size: 72px;
line-height: 78px;
font-family: Josefin Sans, Arial;
@media screen and (max-width: 506px){
  right: 0px;
  left: -20px;
  font-weight: 700;
  font-size: 56px;
  top: 270px ;
  width: 400px;
}
`
export const TextoInfo = styled.div`
position: absolute;
color: #636363;
top: 100px;
left: 80px;
padding-top: 280px;
right: 700px;
font-size: 24px;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
line-height: 38px;
@media screen and (max-width: 506px){
  text-align: center;
  top: 400px;
  right: 0px;
  left: 0px ;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 38px;
  padding-top: 0px;
  width: 500px;
}
@media screen and (max-width: 1506px){
  text-align: center;
  top: 400px;
  left: 50px ;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 38px;
  padding-top: 0px;
  width: 600px;
}
`
export const Btn = styled.button`
position: absolute;
left: 72px;
top: 530px;
border: none;
border-radius: 85px;
padding: 15px 60px 15px 60px;
background-color: #FAD243;
font-family: Josefin Sans, Arial;
font-weight: 600;
font-size: 18px;
cursor: pointer;
:hover{
    transform: scale(1.1);
    transition:  0.2s;
    font-weight: 600;
}
@media screen and (max-width: 506px){
  width: 0%;
  padding: 0px 00px 0px 0px;
  font-size: 0px ;
}
`

export const Sm = styled.div`
position: absolute;
color: #636363;
font-size: 18px;
top: 545px;
left: 475px;
font-family: Josefin Sans, Arial;
font-weight: bold;
cursor:pointer;
@media screen and (max-width: 506px){
  left: 320px ;
  top: 600px ;
  width: 100px;
}
`
export const BtnC = styled.button`
@media screen and (max-width: 506px){
  width: 155px ;
  height: 52px;
  position: absolute;
  left: 72px;
  top: 580px;
  border: none;
  border-radius: 20px;
  background-color: #FAD243;
  font-family: Josefin Sans, Arial;
  font-weight: 600;
  font-size: 18px;

  cursor: pointer;
  :hover{
    transform: scale(1.1);
    transition:  0.2s;
    font-weight: 600;
}
}
`
export const AlinhamentoCards = styled.div`
margin-top: 750px;
@media screen and (max-width:506px){
  margin-top: 650px;
}
`


export const AlinhamentoRodape = styled.div`
@media screen and (max-width:506px){
  width: 506px;
  top: 3100px;
}

@media screen and (max-width: 1506px){

  margin-top: 200px;
}
`
export const AlinhamentoEquipe = styled.div`
margin-top: 100px;
`