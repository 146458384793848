import React,{useEffect, useState, useRef} from "react";
import api from "../../service/api";

import { Body,BodyDiretores,Titulo1,Card,ImgFoto, BodyMembros,Nome,Cargo,Titulo2,Circular } from "./MembrosStyled";

export function Membros(){

    const [membros, setMembros] = useState([]);
    const [diretores, setDiretores] = useState([]);
    const [acessores, setAcessores] = useState([]);
    const [presidencia, setPresidencia] = useState([]);
    const [coordenadores, setCoordenadores] = useState([]);
    const [isLoading, setIsLoading]= useState(true);
    const [update, setUpdate] = useState(false);

    function filtraArray(){
        membros.forEach((elements)=>{
            if(elements.role ==="Assessor" || elements.role ==="Assessora"){
                if(elements.active === "ativo" || elements.active === "Ativo"){
                    acessores.push(elements);
                }
            }else if(elements.role ==="Coordenador" || elements.role ==="Coordenadora"){
                if(elements.active === "ativo" || elements.active === "Ativo"){
                    coordenadores.push(elements);
                }
            }            
            else{
                if(elements.active === "ativo" || elements.active === "Ativo"){
                    diretores.push(elements);
                }}
           
        })
        setUpdate(true);
  
    }   
    useEffect(() => {
        const request = async () => {
          api
            .get("membros")
            .then(async (response) => {
             await setMembros(response.data)
             await setIsLoading(false);
            })
            .catch(async (error) => {
                const { message } = error.response;
                console.log(error.response);
            });
        };
        if (isLoading) {
            request(); 
        }
        if(!update && !isLoading){
            filtraArray();
        }
      },[membros]);
    return (
        <div>
            <Body>
                <Titulo1>Nossos <font color="#FAD243">Diretores</font></Titulo1>
                <BodyDiretores>
                    {diretores.map((membro, key)=>{
                        return(
                            <Card key={membro.id}>
                                <ImgFoto src={membro.profilephoto}/>
                                <Nome>{membro.name}</Nome>
                                <Cargo>{membro.role}{" "}{membro.board}</Cargo>
                            </Card>
                        )
                    })}
                </BodyDiretores>
                <Titulo1>Nossos<font color="#FAD243">Coordenadores</font></Titulo1>
                <BodyDiretores>
                    {coordenadores.map((membro, key)=>{
                        return(
                            <Card key={membro.id}>
                                <ImgFoto src={membro.profilephoto}/>
                                <Nome>{membro.name}</Nome>
                                <Cargo>{membro.role}{" "}{membro.coordination}</Cargo>

                            </Card>
                        )
                    })}
                </BodyDiretores>
                <Titulo2>Nossos <font color="#FAD243">Membros</font></Titulo2>
                <BodyMembros>
                {acessores.map((membro, key)=>{
                        return(
                            <Card key={membro.id}>
                                <ImgFoto src={`${membro.profilephoto}`}/>
                                <Nome>{membro.name}</Nome>
                                <Cargo>{membro.role} {" "}{membro.board}</Cargo>
                            </Card>
                        )
                    })}

                </BodyMembros>
            </Body>
        </div >
    )
}



