import styled, {keyframes, css} from "styled-components"


export const fowardImageAnimac = keyframes`
0%{
    right: -10%;
}
100%{
    right: 0%;
}
`;
export const backwardImageAnimac = keyframes`
0%{
    left: 10%;
}
100%{
    left: 0%;
}
`;

export const StyledImageCarrossel = styled.div`
width: 100%;
height: 100%;

position: absolute;

 ${props => props.imageDir === "forward" 
 ? css`
        animation: ${fowardImageAnimac} 1s ease;
        right: 0;
    `
    :css`
        animation: ${backwardImageAnimac} 1s ease;
        left: 0;
    `
 
 
 }
`
export const StyledImage = styled.img`
width: 100%;
height: 278px;
`
