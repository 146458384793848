import styled from "styled-components"

export const Body = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
width: 100%;
height: 1600px;

@media screen and (max-width: 506px) {
    height: 2500px;
    width: 500px;
}

@media screen and (max-width: 1506px) {
    
}
`

export const TituloA = styled.div`
color:#FAD243 ;
font-size: 18px;
font-family: Josefin Sans, Arial;
font-weight: 500;

@media screen and (max-width: 1506px) {
    height: 100px;
    margin-top: -250px;
    margin-bottom: 25px;
}
`
export const TituloB = styled.div`
color: black;
font-size: 46px;
font-family: Josefin Sans, Arial;
font-weight: bold;

@media screen and (max-width: 506px) {
 text-align: center ;
}

@media screen and (max-width: 1506px) {
    height: 100px;

}
`
export const BodyCards = styled.div`
display: flex;
flex-direction:row;
align-items: center;
justify-content: space-between;
flex-wrap: wrap;
width: 1120px;
height: 359px;

@media screen and (max-width: 506px) {
width: 100%;
flex-direction:row;
height: 459px;
margin-left: 50px;
}

@media screen and (max-width: 1506px) {
    width: 100%;
    flex-direction:column;
    height: 890px;
    margin-left: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    }
`
export const BodyCards2 = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
flex-wrap: wrap;
width: 1120px;
height: 296px;

@media screen and (max-width: 506px) {
width: 100%;
height: 659px;
margin-left: 50px;
}

@media screen and (max-width: 1506px) {
    width: 100%;
    flex-direction:column;
    height: 1300px;
    margin-left: 50px;
    margin-top: 20px;
    margin-bottom: 50px;
    }
`
export const BodyCards3 = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
flex-wrap: wrap;
width: 1120px;
height: 519px;

@media screen and (max-width: 506px) {
width: 100%;
height: 959px;
margin-left: 50px;
}

@media screen and (max-width: 1506px) {
    width: 100%;
    flex-direction:column;
    height: 1800px;
    margin-left: 50px;
    margin-top: 20px;
    margin-bottom: 0px;
    }
`

export const Card = styled.div`
width: 516px;
height: 113px;
@media screen and (max-width: 1506px) {
    margin-bottom:40px;
}
`
export const Icone1 = styled.div`
display: inline-block;
width: 96px;
height: 96px;
background-color: #FAD243;
border-radius: 16px;
@media screen and (max-width: 506px) {
    width: 76px;
    height: 76px;
}
`
export const ImgIcn1 = styled.img`
display: inline-block;
width: 70px;
height: 70px;
margin-top: 15px;
margin-left: 13.5px;
@media screen and (max-width: 506px) {
    width: 50px;
    height: 50px;
}
`
export const Titulo1 = styled.text`
position: absolute;
margin-left: 20px;
color: black;
font-size: 20px;
font-family: Josefin Sans, Arial;
font-weight: bold;
@media screen and (max-width: 506px) {
   
    width: 300px;
}
`
export const Texto5 = styled.text`
position: absolute;
width: 400px;
margin-top: 33px;
margin-left: 20px;
color: #636363;
font-family: Josefin Sans, Arial;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 20px;
letter-spacing: 0.2px;

@media screen and (max-width: 506px) {
    width: 340px;
}

@media screen and (max-width: 1506px) {

}
`