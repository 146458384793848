import styled from "styled-components"

export const BodyCards = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 400px;
flex-wrap: wrap;
justify-content: flex-start;
align-content: space-around;
position: relative;
left: 30px;  
overflow-x: hidden;

@media screen and (max-width: 506px){
width:506px;
height: auto;  
flex-direction: column;
justify-content: space-between;
align-items: center;
align-content: center;
}

@media screen and (max-width: 1500px){
    width: 900px;
    height: auto; 
    display: flex; 
    justify-content: center;
    align-items: center;      
    }
`
export const ImgIcn1 = styled.img`
margin-top: 15px;
margin-left: 15px;
width: 70px;
height: 70px;

`
export const ImgIcn2 = styled.img`
margin-top: 15px;
margin-left: 15px;
width: 69px;
height: 65px;

`
export const Card1 = styled.div` /*todo o card de presidencia*/
width: 540px;
height: 200px;
`
export const Icone1 = styled.div` /*parte amarela*/
width: 96px;
height: 96px;
margin-top: 20px;
margin-left: 10px;
background-color: #FAD243;
border-radius: 16px;
`
export const Titulo1 = styled.div` /* presidencia */
color: black;
font-size: 20px;
font-family: Josefin Sans, Arial;
font-weight: bold;
margin-top: -90px;
margin-left: 130px;

`
export const Info1 = styled.div`
color: #636363;
font-family: Josefin Sans, Arial;
font-weight: 500;
font-size: 18px;
line-height: 20px;
letter-spacing: 0.2px;
margin-left: 130px;
margin-top: 10px;

`
export const Card2 = styled.div`
width: 540px;
height: 200px;
`
export const Icone2 = styled.div`
width: 96px;
height: 96px;
margin-top: 20px;
margin-left: 10px;
background-color: #FAD243;
border-radius: 16px;

`
export const Titulo2 = styled.div` /*vice*/
color: black;
font-size: 20px;
font-family: Josefin Sans, Arial;
font-weight: bold;
margin-top: -90px;
margin-left: 130px;

`
export const Info2 = styled.div`
color: #636363;
font-family: Josefin Sans, Arial;
font-weight: 500;
font-size: 18px;
line-height: 20px;
letter-spacing: 0.2px;
margin-left: 130px;
margin-top: 10px;

`
export const Card3 = styled.div`
width: 540px;
height: 200px;
`
export const Icone3 = styled.div`
width: 96px;
height: 96px;
margin-top: 20px;
margin-left: 10px;
background-color: #FAD243;
border-radius: 16px;
`
export const Titulo3 = styled.div`
color: black;
font-size: 20px;
font-family: Josefin Sans, Arial;
font-weight: bold;
margin-top: -90px;
margin-left: 130px;
`
export const Info3 = styled.div`
color: #636363;
font-family: Josefin Sans, Arial;
font-weight: 500;
font-size: 18px;
line-height: 20px;
letter-spacing: 0.2px;
margin-left: 130px;
margin-top: 10px;
`
export const Card4 = styled.div`
width: 540px;
height: 200px;
`
export const Icone4 = styled.div`
width: 96px;
height: 96px;
margin-top: 20px;
margin-left: 10px;
background-color: #FAD243;
border-radius: 16px;
`
export const Titulo4 = styled.div`
color: black;
font-size: 20px;
font-family: Josefin Sans, Arial;
font-weight: bold;
margin-top: -90px;
margin-left: 130px;
`
export const Info4 = styled.div`
color: #636363;
font-family: Josefin Sans, Arial;
font-weight: 500;
font-size: 18px;
line-height: 20px;
letter-spacing: 0.2px;
margin-left: 130px;
margin-top: 10px;
 
`