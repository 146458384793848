import styled from "styled-components";

export const Alinhamento1 = styled.div`
padding-left: 285px;
padding-top: 80px;
@media screen and (max-width: 506px){
  padding-top: 0px;
}
`
export const Nav = styled.div`
padding: 10px 11rem;
width: 997px;
display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;
background: white;
float: right;
@media screen and (max-width: 506px){
  width: 150px;
  float: none;
}
`
export const Hamburger = styled.div`
display: none;
flex-direction: column;
cursor: pointer;

span{
    height: 3px;
    width: 30px;
    background: black;
    margin-bottom: 4px;
    border-radius: 50px;
}

@media (max-width: 506px){
display: flex;
padding-top: 50px;
}
`
export const MenuLink = styled.a`
position: relative;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
padding: 1rem 1.5rem;

cursor: pointer;
text-align: center;
text-decoration: none;
color: black;
transition: all 0.2s ease-in;
&:hover {
    color: #FAD243;
}
@media screen and (max-width: 506px){
  padding: 1rem 2rem;
}
`
export const Menu = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
position: relative;

@media (max-width: 506px){
overflow: hidden;
flex-direction: column;
width: 100%;
max-height: ${({isOpen}) => (isOpen ? "300px" : "0")};
transition: max-height 0.3s ease-in;
}
`
export const Agendar = styled.button`
position: absolute;
left: 92%;
top: 30px;
font-size: 16px;
border: none;
border-radius: 20px;
padding: 10px 20px 10px 20px;
background-color: #FAD243;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
font-weight: 600;
cursor: pointer;
:hover{
  transform: scale(1.1);
    transition: all 0.2s;
    font-weight: 600;
}
@media screen and (max-width: 506px) {
  display: flex;
  left: 340px;
  top: 53px;
}

@media screen and (max-width: 1506px) {
  display: flex;
  left: 770px;
  top: 30px;
}
`
export const LinkC = styled.a`
text-decoration: none;
color: black;
`