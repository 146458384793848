import React from 'react'
import { Body,TituloA,TituloB,Titulo1,Texto5,BodyCards,Card,Icone1,ImgIcn1,BodyCards2,BodyCards3 } from './PortifolioStyled'
import ImgUsinas from "../../img/usinas.svg"
import ImgIndustriais from "../../img/industriais.svg"
import ImgPrediais from "../../img/prediais.svg"
import ImgConsultoria from "../../img/consultoria.svg"
import ImgDomotica from "../../img/domotica.svg"
import ImgLeitor from "../../img/leitor.svg"
import ImgSolução from "../../img/solucao.svg"
import ImgSistemas from "../../img/sistemas.svg"
import ImgAplicativos from "../../img/aplicativos.svg"
import ImgCommerce from "../../img/commerce.svg"
import ImgWebsite from "../../img/website.svg"
import ImgMej from "../../img/mej.svg"


const Portifolio = () => {
    return(
        <Body>
            <TituloA>NOSSO PORTIFÓLIO</TituloA>
            <TituloB id="at">Elétrica</TituloB>
            <BodyCards>
                <Card>
                    <Icone1>
                        <ImgIcn1 src={ImgUsinas}/>
                    </Icone1>
                    <Titulo1>Usinas Fotovoltaicas</Titulo1>
                    <Texto5>Analisamos a demanda do cliente e, a partir dela, fazemos o dimensionamento da usina. Além disso, fazemos todo o acompanhamento da geração e oferecemos a instalação.</Texto5>
                </Card>
                <Card>
                    <Icone1>
                        <ImgIcn1 src={ImgIndustriais}/>
                    </Icone1>
                    <Titulo1>Plantas Elétricas Industriais</Titulo1>
                    <Texto5>Nossas plantas industriais são feitas através do dimensionamento dos dispositivos a serem implementados e os pontos a serem instalados.</Texto5>
                </Card>
                <Card>
                    <Icone1>
                        <ImgIcn1 src={ImgPrediais}/>
                    </Icone1>
                    <Titulo1>Plantas Elétricas Prediais</Titulo1>
                    <Texto5>Em nossos projetos prediais, entendemos e estudamos as demandas do cliente para elaboramos a planta elétrica para o projeto.</Texto5>
                </Card>
            </BodyCards>
            <TituloB id="at">Eletrônica</TituloB>
            <BodyCards2>
                <Card>
                    <Icone1>
                        <ImgIcn1 src={ImgConsultoria}/>
                    </Icone1>
                    <Titulo1>Consultoria em Eletrônica</Titulo1>
                    <Texto5>Este serviço visa instruir nosso cliente para a realização do seu projeto. Com a consultoria, nossos clientes conseguem o passo a passo para executarem a solução das suas necessidades.</Texto5>
                </Card>
                <Card>
                    <Icone1>
                        <ImgIcn1 src={ImgDomotica}/>
                    </Icone1>
                    <Titulo1>Consultoria em Domótica</Titulo1>
                    <Texto5>A automação residencial (domótica) está cada vez mais em alta e desejada pelos nossos clientes. Com a consultoria nosso cliente terá, num valor bem mais acessível, um guia para executar a automação do seu imóvel.</Texto5>
                </Card>
                <Card>
                    <Icone1>
                        <ImgIcn1 src={ImgLeitor}/>
                    </Icone1>
                    <Titulo1>Leitor Biométrico</Titulo1>
                    <Texto5>O leitor biométrico é uma fechadura eletrônica onde são cadastradas as digitais de acesso para abertura das portas sem a necessidade de chaves.</Texto5>
                </Card>
                <Card>
                    <Icone1>
                        <ImgIcn1 src={ImgSolução}/>
                    </Icone1>
                    <Titulo1>Solução por demanda</Titulo1>
                    <Texto5>Acreditamos que toda ideia pode ser considerada e avaliada para sair do papel. Por isso, oferecemos uma solução buscando suprir todas as suas necessidades.</Texto5>
                </Card>
            </BodyCards2>
            <TituloB id="at">Tecnologia da Informação</TituloB>
            <BodyCards3>
                <Card>
                    <Icone1>
                        <ImgIcn1 src={ImgSistemas}/>
                    </Icone1>
                    <Titulo1>Sistemas de Gerenciamento</Titulo1>
                    <Texto5>Os sistemas são produtos que integram várias plataformas a fim de atingir o objetivo final do projeto, seja ele monitorar a entrada e saída de produtos da sua fábrica ou coletar e tratar os diversos dados dos diversos sistemas que você já utiliza.</Texto5>
                </Card>
                <Card>
                    <Icone1>
                        <ImgIcn1 src={ImgAplicativos}/>
                    </Icone1>
                    <Titulo1>Aplicativos Mobile</Titulo1>
                    <Texto5>Os aplicativos mobile são uma solução pra quem procura a mobilidade que um celular ou tablet oferece e ao mesmo tempo não quer ter o trabalho de entrar no navegador do celular sempre que for acessar a aplicação.</Texto5>
                </Card>
                <Card>
                    <Icone1>
                        <ImgIcn1 src={ImgCommerce}/>
                    </Icone1>
                    <Titulo1>E-commerce</Titulo1>
                    <Texto5>O e-commerce é um produto voltado para empresas que desejam abrir uma plataforma de vendas online. Aqui o nosso foco é expor o produto do nosso cliente da melhor maneira possível.</Texto5>
                </Card>
                <Card>
                    <Icone1>
                        <ImgIcn1 src={ImgWebsite}/>
                    </Icone1>
                    <Titulo1>Website</Titulo1>
                    <Texto5>O time de TI da Atlas desenvolve websites com a cara e o gosto do cliente. se você procura um blog pessoal ou um site voltado para uma empresa ou organização, aqui você quem escolhe.</Texto5>
                </Card>
                <Card>
                    <Icone1>
                       <ImgIcn1 src = {ImgMej}/>
                    </Icone1>
                    <Titulo1>MEJ na Web</Titulo1>
                    <Texto5>O produto consiste em uma série de sites pré-estabelecidos. Escolha o que mais te atende, entre em contato conosco e em até duas semanas o site já estará com as informações da sua empresa e no ar!</Texto5>
                </Card>
            </BodyCards3>
        </Body>
    )
}



export default Portifolio;