import React from "react";
import ImgEletrica from "../../img/eletrica.svg";
import ImgEletronica from "../../img/eletronica.svg";
import ImgTi from "../../img/ti.svg";
import{BodyCards,Card1,Card2,Card3,Icone1,Icone2,Icone3,ImgIcn1,
    ImgIcn2,Eletrica,Texto5,BtnC1,LinkS,Eletronica,Texto6,Texto7,BtnC2,ImgIcn3,Ti,BtnC3} from  './CardsInicialStyled'


const CardsInicial = () => {
    return(
        <div>
            <BodyCards>
                <Card1 >
                    <Icone1>
                        <ImgIcn1 src={ImgEletrica}/>
                        <Eletrica>Elétrica</Eletrica>
                    </Icone1>
                    <Texto5>Buscando opções mais amigáveis com o meio ambiente, os projetistas de elétrica trabalham com energia fotovoltaica e plantas elétricas.</Texto5>
                    <BtnC1><LinkS href="/servicos">Saiba mais</LinkS></BtnC1> 
                </Card1>
                <Card2>
                    <Icone2>
                        <ImgIcn2 src={ImgEletronica}/> 
                        <Eletronica>Eletrônica</Eletronica>
                    </Icone2>
                    <Texto6>Possui o propósito de automatizar os trabalhos e atividades. A integração entre programação e módulos eletrônicos permitem essa realidade em diversas áreas.</Texto6>
                    <BtnC2><LinkS href="/servicos">Saiba mais</LinkS></BtnC2> 
                </Card2>
                <Card3>
                    <Icone3>
                        <ImgIcn3 src={ImgTi}/>
                        <Ti>Tec.Informação</Ti>
                    </Icone3>
                   
                    <Texto7>Possibilitando a inserção dos nossos clientes no mundo virtual, nosso setor de TI oferece diferentes soluções para agilizar, impulsionar e modernizar o seu negócio. </Texto7>
                    <BtnC3><LinkS href="/servicos">Saiba mais</LinkS></BtnC3>
            </Card3>
            </BodyCards>
        </div>
    )
}

export default CardsInicial;