import styled from "styled-components"

export const Body = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
width: 100%;
height: 832px;

@media screen and (max-width: 506px) {
    height: 1975px;
    width: 500px;
}

@media screen and (max-width: 1506px) {
  
}
`
export const Titulo = styled.div`
color:#FAD243 ;
font-size: 18px;
font-family: Josefin Sans, Arial;
font-weight: 500;

@media screen and (max-width: 1506px){
  margin-top: -70px;
}
`
export const Titulo2 = styled.div`
color: black;
font-size: 46px;
font-family: Josefin Sans, Arial ;
font-weight: bold;

@media screen and (max-width: 1506px){
  margin-top: -20px;
}
`
export const Texto4 = styled.div`
color:#636363 ;
font-size: 18px;
font-family: Josefin Sans, Arial ;
line-height: 38px;
width: 440px;
text-align: center;

@media screen and (max-width: 506px){
  
}

@media screen and (max-width: 1506px){
  margin-top: -40px;
}
`
export const BodyCards = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
flex-wrap: wrap;
width: 90%;
height: 471px;

@media screen and (max-width: 506px) {
    height: 1533px;
    width: 362px;
}

@media screen and (max-width: 1506px) {
  
 
  
}
`
export const Card1 = styled.div`
width: 362px;
height: 471px;
background-color: #FFFFFF;
border-width: 3px;
border-color: #EBECED;
border-style: solid;
border-radius: 16px;
transition: .7s;
:hover{
  border-color:#FAD243 ;
}
`
export const Icone1 = styled.div`
width: 96px;
height: 96px;
background-color: #FAD243;
margin-top: 40px;
margin-left: 40px;
border-radius: 16px;
`

export const ImgIcn1 = styled.img`
margin-top: 15px;
margin-left: 15px;
width: 70px;
height: 70px;
`
export const NomeCard = styled.h1`
color: black;
font-size: 24px;
font-family: Josefin Sans, Arial ;
margin-top: 40px;
`
export const Texto5 = styled.div`
color: #636363;
font-size: 18px;
font-family: Josefin Sans, Arial ;
margin-top: 70px;
padding-right: 90px;
margin-left: 40px ;
line-height: 25px;
`