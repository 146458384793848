import {Box, CronogramaText, CronogramaDate,TextDiv, BoxDiv,DateDiv} from './BoxCronogramaStyled';




export function BoxCronograma({TituloCronograma,DataCronograma}){

    return(
        <BoxDiv>
        <Box>
            <TextDiv>

            <CronogramaText>
                {TituloCronograma}
            </CronogramaText>
            </TextDiv>
           <DateDiv>

            <CronogramaDate>
                {DataCronograma}
            </CronogramaDate>
           </DateDiv>
           
        </Box>
        </BoxDiv>
    );
}


