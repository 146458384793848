import styled from "styled-components";

export const Body = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: 100%;
height: 670px;
@media screen and (max-width: 506px){
width: 100%;
height: 955px;
flex-direction: column-reverse;
}

@media screen and (max-width: 1506px){
  flex-direction: row;
  width: 100%;
  height: 955px;
  flex-direction: column-reverse;
  margin-top: 900px;
  }
`
export const BodyCards = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;
height: 480px;
width: 100%;

@media screen and (max-width: 506px){
width: 100%;
height: 616px;
flex-direction: column;
align-items: center;
justify-content: space-between;
}


`
export const CardA = styled.div`
width : 560px;
height: 142px;
background-color: #FFFFFF;
border-width: 3px;
border-color: #EBECED;
border-style: solid;
border-radius: 16px;
transition: .7s;
:hover{
  border-color:#FAD243 ;
}
@media screen and (max-width: 506px){
  width: 466px;
  height: 167px;
  left: 15px;
  top: 2900px;
}

@media screen and (max-width: 1506px){
  margin-left: 30px;
  margin-top: 10px;
}
`
export const Icone4 = styled.div`
margin-top: 23px;
margin-left: 16px;
display: inline-block;
width: 96px;
height: 96px;
background-color: #FAD243;

border-radius: 16px;


`
export const Texto12 = styled.div`
margin-left: 132px;
margin-top: -100px;
color: black;
font-size: 24px;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
line-height: 38px;
font-weight: bold;
`
export const Texto13 = styled.div`
margin-left: 132px;
margin-right: 20px;
color: #636363;
font-size: 18px;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
line-height: 25px;
`
export const ImgIcn = styled.img`
width: 70px;
height: 70px;
margin-top: 15px;
margin-left: 13.5px;
`
export const BodyTxt = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
flex-wrap: wrap;
height: 200px;
width: 100%;
@media screen and (max-width: 506px){
width: 100%;
height: 235px;
align-items: center;
}
`
export const TituloB = styled.div`
color: #FAD243 ;
font-size: 18px;
font-family: Josefin Sans, Arial ;
font-weight: 500;

@media screen and (max-width:506px){
  left: 42px;
  top: 2620px;
}

@media screen and (max-width: 1506px){
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
`
export const Texto8 = styled.div`
color: black;
font-size: 46px;
font-family: Josefin Sans, Arial;
font-weight: bold;
line-height: 65px;
width: 600px;
@media screen and (max-width:506px){
  left: 42px;
  top: 2650px;
  padding-right: 00px;
  font-weight: 700;
  font-size: 36px;
  line-height: 65px;
  width: 450px;
}

@media screen and (max-width: 1506px){
  display: flex;
  margin-left: 200px;
  width: 100%;
  align-items: center;
}
`

export const Texto11 = styled.div`
color: #636363;
font-size: 24px;
width: 500px;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
line-height: 38px;
 
@media screen and (max-width:506px){
  left: 42px;
  top: 2720px;
  padding-right: 00px;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  width: 450px;
}

@media screen and (max-width: 1506px){
  padding-left: 220px;

}
`