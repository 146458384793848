import styled from "styled-components"

export const Body = styled.div`
min-width: 100vw;
height: 100px;
background: #FFFFFF;
align-items: center;
justify-content: space-between;
display: flex;

`
export const LogoDiv=styled.div`
    min-height: 120px;
    min-width: 180px;
    align-items: center;
    display: flex;
    cursor: pointer;
`

export const Logo = styled.img`
background-size: cover;
position: absolute;
margin-left: 100px;
height: 80px;
width: 180px;

`

export const conjutoButtons=styled.div`
    display: flex;
    list-style: none;
    height: auto;

    
`

export const LinkC = styled.a` /* agendar */
    text-decoration: none;
    color: black;
`
export const Agendar = styled.button`
margin-right: 60px;
font-size: 16px;
border: none;
border-radius: 20px;
padding: 10px 20px 10px 20px;
background-color: #FAD243;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
font-weight: 600;
cursor: pointer;
:hover{
  transform: scale(1.1);
    transition: all 0.2s;
    font-weight: 600;
}

@media screen and (max-width: 506px) {
  display: flex;
  left: 340px;
  top: 53px;
}

`
export const MenuLink = styled.a`

text-decoration: none;
margin-right: 45px;
color: black;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
transition: .3s;
:hover {
  color: #FAD243;
}
position: relative;

@media (max-width: 506px){
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  max-height: ${({isOpen}) => (isOpen ? "300px" : "0")};
  transition: max-height 0.3s ease-in;
  }
`