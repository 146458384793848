import React from 'react';
import styled from 'styled-components';


class Count extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
    }
  }

  componentDidMount() {
    // update every second
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(this.props.date);
      date ? this.setState(date) : this.stop();
    }, 1000);
  }

  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0,
    };

    // calculate time difference between now and expected date
    if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) { // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) { // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  stop() {
    clearInterval(this.interval);
  }

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = '0' + value;
    }
    return value;
  }

  render() {
    const countDown = this.state;

    return (
      <div className="Countdown">
      <Estiliza>
        <span className="countdown-col">
          <strong>{this.addLeadingZeros(countDown.days)}</strong>
          <span className="countdown-col2">:</span>
        </span>
        
        <span className="countdown-col">
          <strong>{this.addLeadingZeros(countDown.hours)}</strong>
          <span className="countdown-col2">:</span>
        </span>

        <span className="countdown-col">
          <strong>{this.addLeadingZeros(countDown.min)}</strong>
          <span className="countdown-col2">:</span>
        </span>

        <span className="countdown-col">
          <strong>{this.addLeadingZeros(countDown.sec)}</strong>
          
        </span>
        </Estiliza>
        <EstilizaTitulo>
        <span className="countdown-col1">
          <strong>Dias</strong>
          
        </span>
        
        <span className="countdown-col3">
          <strong>Horas</strong>
          
        </span>

        <span className="countdown-col4">
          <strong>Minutos</strong>
         
        </span>

        <span className="countdown-col5">
          <strong>Segundos</strong>
          
        </span>
        </EstilizaTitulo>
      </div>
    );
  }
}
const Estiliza = styled.div`
display: flex;
justify-content: space-evenly;
align-items: center;

.countdown-col {
     font-family: Digital;
    font-size: 100px;
    color: white;
 
    @media screen and (max-width: 900px) {
      font-size: 55px;
    }

  }
  .countdown-col2 {
    margin-left: 20px;
    margin-right: -100px;
    color: white;
    
  }

`


const EstilizaTitulo = styled.div`
display: flex;
justify-content: space-evenly;
align-items: center;
.countdown-col1 {
    
    font-family: Digital;
    font-size: 30px;
    margin-left:60px;
    color: white;

    @media screen and (max-width: 1500px) {
      font-size: 28px;
      margin-left: 60px;
    }

  }
  .countdown-col3 {
   
    font-family: Digital;
    font-size: 30px;
    margin-left:15px;
    color: white;

    @media screen and (max-width: 1500px) {
      font-size: 28px;
      margin-left: 15px;
    }

  }
  .countdown-col4 {
 
    font-family: Digital;
    margin-right:80px;
    font-size: 30px;
    color: white;
   
    @media screen and (max-width: 1500px) {
      font-size: 28px;
      margin-right: 80px;
    }

  }
  .countdown-col5 {
 
    font-family: Digital;
    font-size: 30px;
    color: white;
    margin-left:-85px;

    @media screen and (max-width: 1500px) {
      font-size: 28px;
      margin-left: -85px;
    }
 
  }
 
`


export default Count;
