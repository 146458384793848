import styled from "styled-components";

export const Body = styled.div`
width: 100%;
height: auto;
@media screen and (max-width: 506px) {
    width: 506px;
}
`
export const BodyDiretores = styled.div`
display: flex;
justify-content: space-around;
flex-direction: row;
margin-left: 10%;
width: 80%;
height: auto;
align-items: center;
flex-wrap: wrap;
gap: 20px;
`
export const BodyMembros = styled.div`
display: flex;
justify-content: space-around;
flex-direction: row;
width: 95%;
margin-left: 2.5%;
height: auto;
align-items: center;
flex-wrap: wrap;
`
export const Card = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-content: center;
gap: 5px;
width: 250px;
height: 220px;
`
export const Titulo1 = styled.div`
display: flex;
justify-content: center;
gap: 15px;
width: 100%;
margin-top: 50px;
margin-bottom: 50px;
font-family: 'Josefin Sans';
font-style: normal;
font-weight: 700;
font-size: 46px;
line-height: 65px;
`

export const ImgFoto = styled.img`
border-radius: 75px;
width: 150px;
height: 150px;
display: block;
margin-left: auto;
margin-right: auto;
`

export const Nome = styled.div`
font-family: 'Josefin Sans';
font-style: normal;
font-weight: 600;
font-size: 24px;
letter-spacing: 0.2px;
text-align: center;
`
export const Cargo = styled.div`
font-family: 'Josefin Sans';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 18px;
letter-spacing: 0.2px;
color: #FFD700;
text-align: center;
`
export const Titulo2 = styled.div`
display: flex;
justify-content: center;
gap: 15px;
width: 100%;
margin-top: 50px;
margin-bottom: 50px;
font-family: 'Josefin Sans';
font-style: normal;
font-weight: 700;
font-size: 46px;
line-height: 65px;
`