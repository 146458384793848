import React, {useState} from "react"
import { StyledArrowContainer,StyledCarrosselContainer } from "./CarrosselStyled";
import AppImage from "../appImage/AppImage"
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

import first from "../../img/slider6.png";
import second from "../../img/slider5.png";
import third from "../../img/slider2.png";
import four from "../../img/slider4.png";




const Images = [first, second, third, four];


const Carrossel = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [direction, setDirection] = useState('forward');

    const nextImage = () => {
        setCurrentImageIndex(prevIndex => prevIndex +1);
        setDirection('forward');
    };

    const preImage = () => {
        setCurrentImageIndex(prevIndex => prevIndex -1);
        setDirection('backward');
    };

return (
    <StyledCarrosselContainer>
            
            <AppImage src={Images[currentImageIndex]} imageDir={direction}/>
            {currentImageIndex !== Images.length - 1 && (
            <StyledArrowContainer onClick={nextImage} leftPosition="80%">
                <IoIosArrowForward/>
            </StyledArrowContainer>
            )}
            {currentImageIndex !== 0 && (
            <StyledArrowContainer onClick={preImage} leftPosition="60%">
                <IoIosArrowBack/>
            </StyledArrowContainer>
            )}
           
        </StyledCarrosselContainer>
        )
}
    



export default Carrossel;