import { useState } from "react";
import { Wrapper,Accordion1,Title,bodyDiv, TextDiv } from "./FaqStyled";

function Accordion() {
    const [selected, setSelected] = useState(null)

    const toggle = (i) => {
        if (selected === i) {
            return setSelected (null)
        }

        setSelected(i)
    }

    return <bodyDiv>
            <TextDiv>
            <Title>FAQ</Title>
            </TextDiv>
        <Wrapper>
            <Accordion1>
                {data.map((item, i) => (
                    <div className="item">
                        <div className="title" onClick={() => toggle(i)}>
                            <h2>{item.question}</h2>
                            <span>{selected === i ? '-' : '+'}</span>
                        </div>
                        <div className={selected === i ? 'content show' : 'content'}>{item.answer}</div>
                    </div>
                ))}
            </Accordion1>
        </Wrapper>
    </bodyDiv>
}

const data = [
    {
    question: 'A partir de qual período posso me inscrever?',
    answer: 'Não exigimos período determinado, qualquer período da Unifei que você estiver é válido. Deixamos como sugestão, logo nos anos iniciais da faculdade, já tentar o processo seletivo, pois os períodos finais muitas vezes está destinado a estágio e não consegue conciliar as duas coisas mais a faculdade.'
    },
    {
    question: 'Quem pode participar do processo seletivo?',
    answer: 'Qualquer aluno matriculado na Universidade Federal de Itajubá - Campus Itabira.'
    },
    {
    question: 'A empresa da remuneração para os membros?',
    answer: 'Não, você entrando em uma empresa júnior você irá fazer trabalho voluntário assim como qualquer outro projeto de extensão vinculado a Unifei. Entretanto, acontece algumas premiações de destaque dentro da empresa para os membros, frequentemente os titãs são presenteados por alguns prêmios devido aos seus esforços e dedicação  diante a empresa.'
    },
    {
    question: 'O processo seletivo será presencial ou online?',
    answer: 'O processo seletivo será presencial e o endereço será informado para todos os candidatos com antecedência.'
    },
    {
    question: 'O que podemos desenvolver dentro da Atlas?',
    answer: 'Podemos nos preparar para o mercado de trabalho. Dentro da atlas temos a oportunidade de entrar sempre em contato com clientes, marcando reunião e apresentando projetos, aprendemos itens que a faculdade não passa para nós estudantes dentro da sala de aula. Além disso, vemos na prática como é um ambiente empresarial através dos projetos e documentações burocráticas, desenvolvemos o trabalho em equipe, aprimoramos nossa comunicação, criamos novas conexões e ainda temos a oportunidade de desenvolver a nossa liderança.'
    },
    {
    question: 'Porque entrar na Atlas?',
    answer: 'A atlas é a segunda empresa júnior da unifei, uma empresa Jr de destaque na região em referência em tecnologia. Na Atlas possui jovens estudantes que desenvolvem todas as tarefas da empresa e contribui com seu crescimento, dentro da atlas temos a oportunidade de aprender ainda mais na parte técnica do nosso curso e ainda adquirir conhecimentos de gestão que muitas empresas sênior aplicam no mercado de trabalho, como metodologias ágeis e afins. Ademais, buscamos pessoas com sede de conhecimento, que veste a camisa no que faz e que está disposto a aprender.'
    },
    {
    question: 'Consigo conciliar faculdade e Atlas?',
    answer: 'Sim, todos os titãs são estudantes da unifei e de vários períodos diferentes. Pessoas que estão na atlas desde do seu primeiro período da faculdade. O segredo é tentar separar o seu tempo destinado a faculdade e empresa. A Atlas possui reuniões onde o horário é flexível para todos os membros, exigimos no máximo 8 hrs semanais do seu tempo. E claro tudo é organização, se souber gerenciar o seu tempo você terá tempo de sobra para se dedicar a faculdade e empresa.'
    },
]



export default Accordion;