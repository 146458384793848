import styled from "styled-components";


export const BoxDiv = styled.div`
    display: flex;
    min-height: 75px;
    min-width: 100vw;
    align-items: center;
    justify-content: center;
 `

export const Box = styled.div`
    display: flex;
    
    min-height: 75px;
    width: 75vw;
    border-bottom-color: black;
    border-bottom: 3px solid black;
    align-items: center;
    justify-content: space-between;
   
`
export const TextDiv=styled.div`
  width: 300px;
  height: 75;
`
export const DateDiv=styled.div`
  width: 300px;
  height: 75;
  display: flex;
  justify-content: end;
`
export const CronogramaText = styled.text`
font-family: Josefin Sans;
 font-style: normal;
 text-align: initial;
 font-weight: 600;
 font-size: 24px;
 line-height: 28px;
`

export const CronogramaDate = styled.text`
 font-family: Josefin Sans;
 font-style: normal;
 font-weight: 600;
 font-size: 24px;
 line-height: 28px;
 text-align: left;
 color: #FAD243;
`