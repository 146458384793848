import styled from "styled-components";

export const BodyEquipe = styled.div`
display: flex;
flex-direction: row;
justify-content: space-evenly;
flex-wrap: wrap;

@media screen and (max-width: 506px) {
    height: 843px;
    width: 500px;  
}

@media screen and (max-width: 1506px) {
    margin-top: 600px; 
    flex-direction: column;
    display: flex;
    align-items: center; 
}
`
export const BodyLeft = styled.div`
display: flex;
flex-direction: column;
justify-content: space-evenly;
width: 40%;
height: 430px;
@media screen and (max-width: 506px) {
    width: 430px; 
    align-items: center;
}
`
export const BodyRight = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: 40%;
height: 430px;
@media screen and (max-width: 506px) {
    width: 430px;   
}
`
export const Titulo = styled.div`
color: #FAD243;
font-size: 18px;
font-family: Josefin Sans, Arial;
font-weight: 500;

@media screen and (max-width: 1506px) {
    width: 466px;   
    text-align: center;
    margin-left: -50px;
}
`
export const Texto1 = styled.div`
width: 440px;
font-family: Josefin Sans, Arial;
font-style: normal;
font-weight: 700;
font-size: 46px;
line-height: 65px;
letter-spacing: 0.2px;
@media screen and (max-width: 506px) {
    width: 466px;
    text-align: center;
}
@media screen and (max-width: 1506px) {
    width: 466px;   
    text-align: center;
    margin-left: -50px;
}
`
export const Texto2 = styled.div`
width: 480px;
font-family: Josefin Sans, Arial;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 38px;
letter-spacing: 0.2px;

@media screen and (max-width: 506px) {
    width: 466px;   
    text-align: center;
}

@media screen and (max-width: 1506px) {
    width: 466px;   
    text-align: center;
    margin-left: -50px;
}
`
export const Img = styled.img`
width: 560px;
height: 321px;
border-width: 3px;
border-color: #EBECED;
border-style: solid;
border-radius: 16px;
@media screen and (max-width: 506px) {
    height: 246px;
    width: 430px;
    
}
`