import Img1 from '../../img/img1.png';
import {CronogramaBackGroud, CronogramaDiv,CronogramaHeader,HeaderDiv } from './CronogramaStyled'
import {BoxCronograma } from './boxCronograma/BoxCronograma';





export function Cronograma(){

    return(
        <CronogramaDiv>
            <HeaderDiv>
            <CronogramaHeader>
                Cronograma
            </CronogramaHeader>
            </HeaderDiv>
            <BoxCronograma
            TituloCronograma='Período de Inscrições'
            DataCronograma="19/08 até 06/09"   
            />
            <BoxCronograma
            TituloCronograma='Dinâmicas'
            DataCronograma="09/09"   
            />
            <BoxCronograma
            TituloCronograma='Entrevistas'
            DataCronograma="16/09 até 27/09"   
            />
            {/* <BoxCronograma
            TituloCronograma='Resultado Inicial'
            DataCronograma="22/04"   
            /> */}
            <BoxCronograma
            TituloCronograma='Período Trainee'
            DataCronograma="30/09 até 03/11"   
            />
            {/* <BoxCronograma
            TituloCronograma='Resultado Final'
            DataCronograma="28/05"   
            /> */}
        </CronogramaDiv>
    );
}