import Countdown from 'react-countdown';
import {CountDownDiv,HeaderTitle,TitleHeaderDiv,DivBody,
  ButtonsDiv,CountDownText,CountDownTitle,CountDownBody,TextYellow, Btn,LinkC} from './CountdownStyled';
import Count from './Count';



export function CountDown(){
  return(
    <DivBody>
    <TitleHeaderDiv>
      <HeaderTitle>
        <TextYellow>
        Inscreva-se&nbsp;
        </TextYellow>
      no processo seletivo
      </HeaderTitle>
    </TitleHeaderDiv>
      <CountDownBody>
    <CountDownDiv>
    <TitleHeaderDiv>
      <CountDownTitle>
      Período de inscrições
      </CountDownTitle>
    </TitleHeaderDiv>
    <TitleHeaderDiv>
      <CountDownText>
      Restam
      </CountDownText>
    </TitleHeaderDiv>
      <Count date='2024-09-06T23:59:59'/>
    </CountDownDiv>
      </CountDownBody>
    <ButtonsDiv>
    <Btn><LinkC href='https://docs.google.com/forms/d/e/1FAIpQLScfl98np4ZmhH5plBLPwAgqE2t-LSkqj0qPu-msEk06ExDkkw/viewform' target="_blank">Inscreva-se aqui</LinkC></Btn> 
    <Btn><LinkC href="https://drive.google.com/file/d/1RJT98-x31EttEjqj45Ec6MAyYj-3DcgB/view?usp=sharing" target="_blank">Edital 2024.2</LinkC></Btn> 
    </ButtonsDiv>
    </DivBody>
  );
}