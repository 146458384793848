import React from "react";
import { Link } from "react-scroll";
import Navbar from "../../components/Navbar/Navbar";
import Rodape from "../../components/Rodape/Rodape";
import Imglogo from "../../img/logoboa.png";
import Img1 from "../../img/img1.png";
import ImgBack from "../../img/background.png"
import Testparceiros from '../../components/Parceiros/Parceiros'
import CardsInicial from "../../components/CardsInicial/CardsInicial";
import Dados from "../../components/Dados/Dados";
import PqEscolherAtlas from "../../components/PqEscolherAtlas/PqEscolherAtlas";
import SessãoCarrossel from "../../components/Carrossel/SessãoCarrossel";
import { Imgstilus,Logo1,Menu1,Texto1,Texto2,Texto3,TextoInfo,Btn,BtnC,Sm,AreaAt,TituloA,AlinhamentoCards,LinkC,Vant,Background,AlinhamentoPqEs,AlinhamentoDados,AlinhamentoParceiros,AlinhamentoRodape,AlinhamentoSessãoCar } from "./InicialStyled";
const Inicial = () => {
  
  return (
    <div>
      <Imgstilus src={Img1}/>
      <Logo1 src={Imglogo}/> 
      <Navbar/>
      <Menu1> 
        <Texto1>Não Imagine,</Texto1>
        <Texto2>Concretize!</Texto2>
        <TextoInfo>Tire todos seus projetos e ideias do papel, conte com uma Empresa Júnior considerada por três anos consecutivos de Alto Crescimento.</TextoInfo>
        <Btn><LinkC href="https://calendly.com/contato-atlas/diagnostico" target="_blank">Agende uma conversa</LinkC></Btn>
        <BtnC><LinkC href="https://calendly.com/contato-atlas/diagnostico" target="_blank">Agende</LinkC></BtnC>    
         <Sm><Link
                activeClass="active"
                to="at"
                spy={true}A
                smooth={true}
                offset={-70}
                duration={500}
                > Saiba mais</Link></Sm>      
      </Menu1>
      <AreaAt >
        <TituloA id="at">ÁREA DE ATUAÇÃO</TituloA>
        <Texto3>Nós atuamos em três áreas para te ajudar a <font color="#FAD243">realizar o seu projeto</font></Texto3>
      </AreaAt>
        <AlinhamentoCards>
          <CardsInicial/>
        </AlinhamentoCards>
        <AlinhamentoPqEs>
          <PqEscolherAtlas></PqEscolherAtlas>
          </AlinhamentoPqEs> 
      <Vant>
        <Background src={ImgBack}/>
        
      </Vant>
      <AlinhamentoSessãoCar>
        <SessãoCarrossel></SessãoCarrossel>  
      </AlinhamentoSessãoCar>
        
        <AlinhamentoDados>
            <Dados/>
        </AlinhamentoDados>

        <AlinhamentoParceiros>
          <Testparceiros/>
        </AlinhamentoParceiros>
       
       <AlinhamentoRodape/>
            <Rodape/>
        <AlinhamentoRodape/>
        
    </div>
  );
};



export default Inicial;
