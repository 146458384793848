import styled from "styled-components";

export const StyledCarrosselContainer = styled.div`
width: 548px;
height: 478px;
margin: 20px auto;
display: flex;
justify-content: center;
align-items: center;
position: relative;
display: inline-block;
@media screen and (max-width:506px){
    width: 450px;
    height: 512.28px;
    
}
@media screen and (max-width:470px){
    width: 390px;
    height: 428px;
    
}
`

export const StyledArrowContainer = styled.div`
font-size: 32px;
width: 84px;
height: 52px;
position: absolute;
color: #636363;
border-width: 3px;
border-color: #636363;
border-style: solid;
background-color: white;
display: flex;
justify-content: center;
align-items: center;
left: ${props=>props.leftPosition};
top: 80%;
border-radius: 85px;
cursor: pointer;
transition: .4s;
:hover{
    color: black;
    background-color: #FAD243;
    border-width: 3px;
    border-color: #FAD243;
    border-style: solid;
}
@media screen and (max-width:470px){
    width: 70px;
    height: 43px;
    
}
`