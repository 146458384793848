import React, {useState, useEffect} from "react";
import {StyledImage,StyledImageCarrossel} from './AppImageStyled';



const AppImage = ({ src, imageDir }) => {
    const [currentImage, setCurrentImage] = useState ('');

    useEffect (()=>{
        setCurrentImage(src);
    },[src])

    return (
    <>
    {
    currentImage === src && <StyledImageCarrossel imageDir={imageDir}>
                <StyledImage src={src}/>
            </StyledImageCarrossel>
     }        
    </> 

)
}

export default AppImage;