import styled from "styled-components";

export const Imgstilus = styled.img`
position: absolute;
display: inline-block;
width: 780px;
height: 670px;
`
export const Logo1 = styled.img`
position: absolute;
top: 20px;
display: inline-block;
padding-left: 80px;
height: 80px;
width: 180px;
`
export const Menu = styled.div`
`
export const Texto1 = styled.div`
position: absolute;
top: 200px;
left: 80px;
font-size: 72px;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
font-style: normal;
font-weight: bold;
`
export const TextoInfo = styled.div`
position: absolute;
color: #636363;
left: 80px;
top: 320px;
padding-right: 700px;
font-size: 24px;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
line-height: 38px;
`
export const Btn = styled.div`
position: absolute;
left: 72px;
top: 450px;
border: none;
border-radius: 20px;
padding: 15px 60px 15px 60px;
background-color: #FAD243;
font-family: Josefin Sans, Arial;
font-weight: 600;
font-size: 18px;
cursor: pointer;
:hover{
  transform: scale(1.1);
    transition:  0.2s;
    font-weight: 600;
}
`
export const Sm = styled.div`
position: absolute;
color: #636363;
font-size: 18px;
top: 465px;
left: 475px;
font-family: Josefin Sans, Arial;
font-weight: bold;
`