import styled from "styled-components";

export const Body = styled.div`
    display: flex;
     flex-direction: column;
     justify-content: space-evenly;
     align-items: center;
     align-content:flex-end;
     width: 100%;
     height: 420px;
    
 @media screen and (max-width:506px){
    height: 800px;
    align-content:center;
    justify-content: space-around;
}

@media screen and (max-width: 1506px){
    
}

`
export const BodyTxt = styled.div`
    display: flex;
     flex-direction: column;
     justify-content: space-evenly;
     align-items: center;
     align-content:flex-end;
     width: 100%;
     height: 150px;
     @media screen and (max-width:506px){
        flex-direction: column;
        justify-content: space-between;
}

`

export const Texto1 = styled.div`


color:#FAD243 ;
font-size: 18px;
font-family: Josefin Sans, Arial ;
font-weight: 500;

@media screen and (max-width:506px){
    text-align: center;
}

`
export const Texto2 = styled.text`
color: black;
font-size: 46px;
font-family: Josefin Sans, Arial ;
font-weight: bold;

@media screen and (max-width:506px){
    font-size: 36px;
    text-align: center;
}

@media screen and (max-width: 1506px){
    margin-left: 50px;
    text-align: center;
}
`
export const BodyImgs = styled.div`
    display: flex;
     flex-direction: row;
     justify-content: space-evenly;
     align-items: center;
     align-content:flex-end;
     width: 100%;
     height: 250px;
     @media screen and (max-width:506px){
        flex-direction: column;
        height: 400px;
        justify-content: flex-start;
}
`

export const UnifeiLogo = styled.img`
width: 150px;
height: 150px;

@media screen and (max-width:506px){
   
}
`
export const NuvaLogo = styled.img`

width: 150px;
height: 150px;

@media screen and (max-width:506px){
    
}
`
export const FejemgLogo = styled.img`

width: 150px;
height: 150px;

@media screen and (max-width:506px){
  
}
`
export const BjLogo = styled.img`
width: 200px;
height: 150px;

@media screen and (max-width:506px){
    
}
`