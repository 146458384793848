import React from "react"
import { Body,Agendar, MenuLink,LinkC,Logo,conjutoButtons,LogoDiv } from "./HeaderStyled"
import Imglogo from "../../img/logoboa.png";
export function Header (YellowText, BlackText, ){
    
    return(
      
    <Body>
      <LogoDiv>
      <Logo src={Imglogo}/>
      </LogoDiv>
      <conjutoButtons>
      <MenuLink href="/">
            Início
          </MenuLink>
      <MenuLink href="/servicos">
            Serviços
          </MenuLink>
          <MenuLink href="/nos">
            Nós
          </MenuLink>
          <MenuLink href="/ps">
            Seja um Titã
          </MenuLink>
        
     
      <Agendar><LinkC href="https://calendly.com/contato-atlas/diagnostico" target="_blank">Agendar</LinkC></Agendar>
      </conjutoButtons>
    </Body>
    
)}



