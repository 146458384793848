import React from "react";

import ImgPresidencia from "../../img/presidencia.svg";
import ImgVp from "../../img/vp.svg";

import ImgAdmfin from "../../img/admfin.svg";
import ImgGp from "../../img/gp.svg";
import {BodyCards,Card1,Card2,Card3,Card4,Icone1,Icone2,Icone3,Icone4,ImgIcn1,
    ImgIcn2,Info1,Info2,Info3,Info4,Titulo1,Titulo2,Titulo3,Titulo4} from './CardGestaoStyled';


const CardsGestao = () => {
    return (
        <div>
            <BodyCards>
                <Card1>
                    <Icone1>
                        <ImgIcn1 src={ImgPresidencia}/>
                    </Icone1>
                    <Titulo1>Presidência</Titulo1>
                    <Info1>A presidência tem como função elaborar um política de planejamento, buscar parcerias estratégicas e estabelecer uma comunicação da empresa com o externo. Pelas coordenadorias de Relações Públicas e de Clientes.</Info1>
                </Card1>
                <Card2>
                    <Icone2>
                        <ImgIcn2 src={ImgVp}/>
                    </Icone2>
                    <Titulo2>Vice-presidência</Titulo2>
                    <Info2>Nesta diretoria, é implementada e coordenada a estratégia da empresa. Possui dentro dela a Coordenadoria de Qualidade. Aqui, definimos indicares de qualidade e fazemos a gestão dos projetos por meio de metodologias ágeis.</Info2>
                </Card2>
                <Card3>
                    <Icone3>
                        <ImgIcn2 src={ImgAdmfin}/>
                    </Icone3>
                    <Titulo3>Administrativo e Financeiro</Titulo3>
                    <Info3>Na diretoria de Adm-Fin, trabalhamos diariamente com a execução de aquisições, a organização de balanços e o cuidado de todos os documentos da empresa.</Info3>
                </Card3>
                <Card4>
                    <Icone4>
                        <ImgIcn2 src={ImgGp}/>
                    </Icone4>
                    <Titulo4>Gestão de Pessoas</Titulo4>
                    <Info4>A diretoria de Gestão de Pessoas é responsável por trabalhar formas de motivação e engajamento para os membros, organização de processo seletivo, organização de eventos exteriores e interiores da empresa, cultura organizacional e desenvolvimento dos membros.   </Info4>
                </Card4>
            </BodyCards>
        </div >
    )
}



export default CardsGestao