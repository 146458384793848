import styled from "styled-components";

export const Body = styled.div`
width: 100%;
height: 420px;
display: flex;
flex-direction: row;
justify-content: space-evenly;
align-items: center;
bottom: 0;
align-content:flex-end;
@media screen and (max-width:506px){
    width: 100%;
    height: 550px;
    flex-direction: column;
justify-content: space-evenly;
align-items: center;
align-content:flex-start;
}

@media screen and (max-width:1506px){
  width: 100%;
    height: 550px;
    flex-direction: row;
justify-content: space-evenly;
align-items: center;
margin-top: -200px;
}
`
export const BodyLogo = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
width: 33.33%;
height: 420px;
@media screen and (max-width:506px){
    width: 100%;
    height: 542px;
    
}
`
export const ImgLogo = styled.img`
width: 200px;
height: 200px;
@media screen and (max-width:506px){
    width: 200px;
height: 200px;
}
`
export const Sobre = styled.div`
font-family: Josefin Sans;
font-style: normal;
font-weight: 600;
font-size: 1.125rem;
line-height: 28px;
color: #636363;
text-align: center;
width: 300px;
@media screen and (max-width:506px){
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  width: 450px;
}
`
export const BodyHeader = styled.div`
width: 50.33%;
height: 420px;
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: flex-start;
@media screen and (max-width:506px){
height: 200px ;
width: 50%;
padding: 0px;
margin: 0px;
}

`
export const BodyLink = styled.div`
width: 100%;
height: 220px;
display: flex;
flex-direction: row;
justify-content: space-evenly;
align-items: flex-start;
flex-wrap: wrap;
ul {
  list-style-type: none;
  cursor: pointer;
  line-height: 25px;
  margin: 0px;
  
}
li {
  list-style-type: none;
  cursor: pointer;
  line-height: 25px;
  margin: 0px;
  
}
@media screen and (max-width:506px){
text-align: center;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
}

@media screen and (max-width: 1506px){
  
  }
`
export const Link = styled.a`
text-decoration: none;
color: black;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;

:hover {
  color: #FAD243;
  transition: all 0.2s ease-in;
}
@media screen and (max-width:506px){
  font-size: 0px;
  margin: 0px;
}

@media screen and (max-width: 1506px){
  width: 100%;
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: flex-start;
flex-wrap: wrap;
}
`
export const BodyDireita = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
width: 33.33%;
height: 420px;

@media screen and (max-width:1506px){
  width: 50%;
}
`
export const BodyRedesSociais = styled.div`
display: flex;
flex-direction: row;
justify-content: space-evenly;
align-items: flex-end;
width: 43.33%;
height: 160px;
@media screen and (max-width:506px){
    height: 20px;
    width: 50px;
}
`

export const MenuLink = styled.a`
color: blue;
:hover {
  color: #FAD243;
}
@media screen and (max-width:506px){
  
}
`;
export const LogoInsta = styled.img`
width: 50px;
height: 50px;
@media screen and (max-width:506px){
}
`
export const LogoFace = styled.img`
width: 50px;
height: 50px;
`
export const LogoLinkedin = styled.img`
width: 50px;
height: 50px;
`
export const BodyTelefone = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
width: 33.33%;
height: 120px;
@media screen and (max-width:506px){
    width: 00%;
    height: 00px;
}

@media screen and (max-width:1506px){
  width: 100%;
}
`
export const Telefone = styled.text`
font-family: Josefin Sans;
width: 212px;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 28px;
color: #636363;
@media screen and (max-width:506px){
  width: 0px;
  font-size: 0px;
}
`
export const PhoneIcon = styled.img`
width: 20px;
height: 20px;
@media screen and (max-width:506px){
  width: 0%;
  font-size: 0px;
}
`
export const BodyInformaçoes = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
width: 100%;
height: 420px;
@media screen and (max-width:506px){
    width: 00%;
    height: 00px;
}

@media screen and (max-width:1506px){
  width: 100%;
  
}
`

export const Email = styled.div`
font-family: Josefin Sans;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 28px;
color: #636363;
@media screen and (max-width:506px){
  width: 0%;
  font-size: 0px;
}

`
export const Endereço = styled.div`
font-family: Josefin Sans;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 28px;
margin-top: 10px;
color: #636363;
width: 250px;
@media screen and (max-width:506px){
  width: 0%;
  font-size: 0px;
}
`
export const Atlas = styled.div`
font-family: Josefin Sans;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 28px;
color: #636363;
margin-top: 50px;
@media screen and (max-width:506px){
  width: 0%;
  font-size: 0px;
}
`
export const BodyAtlas = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: center;
width: 100%;
@media screen and (max-width:506px){
    width: 00%;
    height: 00px;
}
`
export const Link2 = styled.a`
text-decoration: none;
color: black;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
font-size: 0px;
:hover {
  color: #FAD243;
  transition: all 0.2s ease-in;
}
@media screen and (max-width:506px){
  font-size: 18px;
}
`