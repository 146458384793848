import React from "react";
import ImgEletrica from "../../img/eletrica.svg";
import ImgEletronica from "../../img/eletronica.svg";
import ImgTi from "../../img/ti.svg";
import {BodyCards,Card1,Card2,Card3,Icone5,Icone6,Icone7,ImgIcn1,Info5,Info6,Info7,Lista1,Lista2,Lista3,Titulo5,Titulo6,Titulo7} from './CardsProjetoStyled'

const CardsProjeto = () => {
    return (
        <div>
            <BodyCards>
                <Card1>
                    <Icone5>
                        <ImgIcn1 src={ImgEletrica}/>
                    </Icone5>
                    <Titulo5>Elétrica</Titulo5>
                    <Info5>Na diretoria de elétrica trabalhamos com projetos de instalação elétrica residencial e industrial e com o dimensionamento de usinas fotovoltaicas. Nossos membros se deparam no dia a dia com:</Info5>
                    <Lista1>
                        <ul>
                            <li>a elaboração de plantas e diagramas com a utilização de softwares;</li>
                            <li>a organização de documentos para a realização de projetos;</li>
                            <li>a preparação de estudos nos mercados de geração fotovoltaica e de qualidade de energia.</li>
                        </ul>
                    </Lista1>
                </Card1>
                <Card2>
                    <Icone6>
                        <ImgIcn1 src={ImgEletronica}/>   
                    </Icone6>
                    <Titulo6>Eletrônica</Titulo6>
                    <Info6>Na diretoria, desenvolvemos serviços e produtos nas áreas de prototipagem eletrônica, consultorias em eletrônica e automação residencial. Assim, permitimos que os membros coloquem em prática:</Info6>
                    <Lista2>
                        <ul>
                            <li>a montagem de circuitos eletrônicos com a utilização de microcontroladores e placas de prototipagem eletrônica, como o arduino;</li>
                            <li>a elaboração de algoritmos e o desenvolvimento da programação;</li>
                            <li>o estudo das tendências do mercado eletrônico e da domótica, com foco no crescimento.</li>
                        </ul>
                    </Lista2>
                </Card2>
                <Card3>
                    <Icone7>
                        <ImgIcn1 src={ImgTi}/>
                    </Icone7>
                    <Titulo7>Tecnologia da Informação</Titulo7>
                    <Info7>Na diretoria, são desenvolvidos diversos tipos de projetos, como sites, aplicativos mobile, sistemas desktop, e-commerce e sistemas de gerenciamento. No nosso cotidiano, utilizamos:</Info7>
                    <Lista3>
                        <ul>
                            <li>no desenvolvimento web, com React, adotado em grandes empresas como Facebook, Instagram, Discord, e Node.js, utilizado no Netflix, Uber, Linkedin;</li>
                            <li>no desenvolvimento mobile, com Flutter, um framework criado e utilizado pela Google, e também em empresas como Nubank, iFood, entre outros.</li>
                        </ul>
                    </Lista3>
                </Card3>
            </BodyCards>
        </div>
    )
}



export default CardsProjeto