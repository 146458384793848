import styled from "styled-components";

export const Menu = styled.div`
`
export const Np = styled.div`
`
export const LinkC = styled.a`
text-decoration: none;
color: black;
`
export const Background = styled.img`
margin-top: 720px;
width: 810px;
height: 1740px;
@media screen and (max-width: 506px){
  width: 0%;
  padding: 0px 00px 0px 0px;
  font-size: 0px ;
}
`
export const Imgstilus = styled.img`
position: absolute;
display: inline-block;
width: 780px;
height: 670px;
@media screen and (max-width: 506px){
  width: 0% ;
}
`
export const Logo = styled.img`
position: absolute;
top: 20px;
display: inline-block;
padding-left: 80px;
height: 80px;
width: 180px;
@media screen and (max-width:506px){
  left: -50px ;
  top: 40px;
}
`
export const Texto1 = styled.div`
position: absolute;
color: black;
top: 190px;
left: 80px;
right: 400px;
font-style: normal;
font-weight: bold;
font-size: 72px;
line-height: 78px;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
width: 600px;
@media screen and (max-width:506px){
    text-align: center;
    font-weight: 700;
    font-size: 56px;
    left: 25px;
    right: 0;
    z-index: -1;
    width: 450px;
}
`
export const TextoInfo = styled.div`
position: absolute;
color: #636363;
left: 80px;
top: 385px;
right: 700px;
font-size: 24px;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
line-height: 38px;
width: 600px;
@media screen and (max-width: 506px){
    text-align: center;
    left: 20px;
    right: 0;
    width: 460px;
}
`
export const Btn = styled.button`
position: absolute;
left: 72px;
top: 500px;
border: none;
border-radius: 85px;
padding: 15px 60px 15px 60px;
background-color: #FAD243;
font-family: Josefin Sans, Arial;
font-weight: 600;
font-size: 18px;
cursor: pointer;
:hover{
  transform: scale(1.1);
    transition: all 0.2s;
    font-weight: 700px;
}
@media screen and (max-width: 506px){
  width: 0%;
  padding: 0px 0px 0px 0px;
  font-size: 0px ;
}
`
export const BtnC = styled.button`
@media screen and (max-width: 506px){
  width: 155px ;
  height: 52px;
  position: absolute;
  left: 72px;
  top: 500px;
  border: none;
  border-radius: 20px;
  background-color: #FAD243;
  font-family: Josefin Sans, Arial;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  :hover{
    transform: scale(1.1);
    transition:  0.2s;
    font-weight: 600;
}
}
`
export const Sm = styled.div`
position: absolute;
color: #636363;
font-size: 18px;
top: 515px;
left: 475px;
font-family: Josefin Sans, Arial;
font-weight: bold;
width: 100px;
cursor: pointer;
@media screen and (max-width:506px) {
  left: 320px ;
  top: 515px ;
  width: 100px;
}
`
export const AlinhamentoPortif = styled.div`
width: 100%;
margin-top: 900px;
position: absolute;
@media screen and (max-width:506px){
    margin-top: 550px;
  }
`
export const AlinhamentoSessãoCar = styled.div`
@media screen and (max-width:1506px){
  margin-top: -650px;
  }
`


export const AlinhamentoRodape = styled.div`
height: 400px;
width: 100%;
background: #FFFFFF;
display: inline-block;
position: relative;

@media screen and (max-width:1506px){
  margin-top: -100px;
}
`