import React from 'react'
import { Link } from "react-scroll";
import Img1 from "../../img/img1.png";
import Imglogo from "../../img/logoboa.png";
import Navbar from "../../components/Navbar/Navbar";
import {Membros}from '../../components/Membros/Membros';
import Equipe from '../../components/Equipe/Equipe';
import Rodape from '../../components/Rodape/Rodape';
import CardsNós from '../../components/CardsNós/CardsNós';
import { Imgstilus,Logo,LinkC,Texto1,Texto2,Texto3,TextoInfo,Btn,BtnC,Sm,AlinhamentoCards,AlinhamentoEquipe,AlinhamentoRodape } from './NósStyled';

const Nós = () =>  {
    return(
        <div>
            
            <Imgstilus src={Img1}/>
            <Logo src={Imglogo}/> 
            <Navbar/>
            
            <Texto1>Atlas</Texto1>
            <Texto2>Soluções</Texto2>
            <Texto3>em Tecnologia</Texto3>
            <TextoInfo>Somos uma Empresa Júnior vinculada à Universidade Federal de Itajubá - Campus Itabira.</TextoInfo>
            <Btn><LinkC href="https://calendly.com/contato-atlas/diagnostico" target="_blank">Agende uma conversa</LinkC></Btn>
            <BtnC><LinkC href="https://calendly.com/contato-atlas/diagnostico" target="_blank">Agende</LinkC></BtnC>
            <Sm><Link
                activeClass="active"
                to="at"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                > Saiba mais</Link></Sm>  
            <AlinhamentoCards>
              <CardsNós></CardsNós>
            </AlinhamentoCards>

            <AlinhamentoEquipe>
            <Equipe/>
            </AlinhamentoEquipe>
            <Membros/>
            <AlinhamentoRodape>
              <Rodape></Rodape>
            </AlinhamentoRodape>
        </div>
    )
}





export default Nós
