import React from "react";
import {Header}from '../../components/Header/Header'
import Imglogo from "../../img/logoboa.png";
import Img1 from "../../img/img1.png";
import { Imgstilus,Logo1,Menu,Texto1,TextoInfo,Btn,Sm } from "./BlogStyled";

const Blog = () => {
    return (
    <div>
    <Imgstilus src={Img1}/>
      <Logo1 src={Imglogo}/>  
      <Header></Header>
      <Menu>
        <Texto1><font color="#2C2C2C">O blog da <font color="#FAD243"> Atlas </font></font></Texto1>
        <TextoInfo>Os conteúdos produzidos pela nossa empresa. Feito com carinho pelos membros da Atlas.</TextoInfo>
        <Btn>Agende uma conversa</Btn>
        <Sm>Saiba mais</Sm>
      </Menu>
      

    </div>
    )
}


export default Blog;