import styled from "styled-components";
import Img1 from '../../img/img1.png';


export const CronogramaDiv = styled.div`
min-width: 100vw;
min-height: 500px;
background-color: #FFF;
margin-bottom: 100px;
`
export const HeaderDiv= styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 25px;
`
export const CronogramaHeader=styled.text`
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
font-style: normal;
font-weight: bold;
font-size: 56px;
line-height: 78px;
letter-spacing: 0.2px;
color: black;
text-align: center;
`