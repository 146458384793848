import styled from "styled-components";

export const Body = styled.div`
height: 1480px;
width: 100%;
background-color: white;
display: inline-block;
margin-bottom: 200px;

@media screen and (max-width: 1506px){
    margin-top: 100px;
    }
`
export const Titulo = styled.text` /* como a atlas funciona */
display: flex;
justify-content: center;
align-items: center;
height: 15vh;
font-family: 'Josefin Sans';
font-weight: 700;
font-size: 56px;
@media screen and (max-width: 506px){
width: 506px;
height: auto;
text-align: center;
}
`
export const TituloA = styled.div` /* diretorias de gestao */
display: flex;
justify-content: center;
align-items: center;
height: 12vh;
font-family: 'Josefin Sans';
font-weight: 700;
font-size: 46px;
@media screen and (max-width: 506px){
width: 506px;
height: auto;
text-align: center;
}
`

export const TituloB = styled.div` /* diretorias de projeto */
display: flex;
justify-content: center;
align-items: center;
height: 12vh;
font-family: 'Josefin Sans';
font-weight: 700;
font-size: 46px;
@media screen and (max-width: 506px){
width: 506px;
height: auto;
text-align: center;
}
`