import styled from "styled-components";


export const Imgstilus = styled.img`
position: absolute;
display: inline-block;
width: 780px;
height: 670px;
`

export const Menu1 = styled.div` 
display:flex;
flex-direction: column;
width: 100%;
height: 550px;
justify-content: space-evenly;
position: relative;
left: 90px;
`
export const Texto1 = styled.div` /* seja um */
position: relative;
font-size: 72px;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
font-style: normal;
font-weight: bold;

@media screen and (max-width: 1500px) {
  font-size: 66px;
}
`

export const Texto2 = styled.div` /* Tita do titulo */
position: relative;
color: #FAD243;
font-size: 72px;
font-family: Josefin Sans, Arial;
font-style: normal;
font-weight: bold;

@media screen and (max-width: 1500px) {
  font-size: 66px;
}

`

export const TextoInfo = styled.div` /* aguarde a sua chance... */
position: relative;
color: #636363;
padding-right: 700px;
font-size: 24px;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
line-height: 38px;
width: 550px;

@media screen and (max-width: 1500px) {
  font-size: 22px;
}
`
export const Btn = styled.button` /* faca sua inscricao */
position: relative;
border: none;
border-radius: 85px;
padding: 15px 60px 15px 60px;
background-color: #FAD243;
font-family: Josefin Sans, Arial;
font-weight: 600;
font-size: 18px;
cursor: pointer;
:hover{
  transform: scale(1.1);
    transition:  0.2s;
    font-weight: 600;
}

@media screen and (max-width: 1500px) {
  font-size: 16px;
  padding: 11px 40px 11px 40px;
}
`
export const BtnC = styled.button`
@media screen and (max-width: 1024px){
  width: 155px ;
  height: 52px;
  position: relative;
  border: none;
  border-radius: 20px;
  background-color: #FAD243;
  font-family: Josefin Sans, Arial;
  font-weight: 600;
  font-size: 18px;

  cursor: pointer;
  :hover{
    transform: scale(1.1);
    transition:  0.2s;
    font-weight: 600;
}
}
`
export const Sm = styled.div` /* saiba mais */
display: flex;
position: relative;
color: #636363;
font-size: 18px;
font-family: Josefin Sans, Arial;
font-weight: bold;
width: 100px;
align-items: center;
cursor: pointer;

@media screen and (max-width: 1500px) {
left: 30px;
}

`
export const LinkC = styled.a`
text-decoration: none;
color: black;
`
export const Link = styled.a`
text-decoration: none;
color: #636363;
`
export const ContainerButton= styled.div`
flex-direction: row;
width: 30vw;
display: flex;
justify-content: space-between;

`

export const ContainerText= styled.div` /* seja um */
flex-direction: row;
width: 100vw;
align-items: center;
display: flex;
`
