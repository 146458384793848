import React from "react";
import ImgMissão from "../../img/missao.svg";
import ImgVisão from "../../img/visao.svg";
import ImgValores from "../../img/valores.svg";
import  {Body,Titulo,Titulo2,Texto4,Texto5,Icone1,ImgIcn1,BodyCards,Card1,NomeCard} from './CardsNósStyled'

const CardsNós = () => {
    return(
        <Body>
            <Titulo>NOSSA ESSÊNCIA</Titulo>
            <Titulo2 id="at">A<font color="#FAD243"> Atlas</font></Titulo2>
            <Texto4>Temos como proposta, desenvolver soluções tecnológicas de qualidade e confiabilidade, por meio de projetos nas áreas de Engenharia Elétrica, Engenharia de Controle e Automação e Engenharia da Computação.</Texto4>
            <BodyCards>
                <Card1 >
                    <Icone1>
                        <ImgIcn1 src={ImgMissão}/>
                        <NomeCard>Missão</NomeCard>
                    </Icone1>
                    <Texto5>Ser provedora de mudanças por meio da vivência empresarial e da qualidade em projetos, a fim de
impactar o mercado e transformar pessoas em líderes capazes de melhorar a realidade em que estão
inseridos. </Texto5>
                </Card1>
                <Card1 >
                    <Icone1>
                        <ImgIcn1 src={ImgVisão}/>
                        <NomeCard>Visão</NomeCard>
                    </Icone1>
                    <Texto5>Até 2024 ser referência para o mercado de itabira e região desenvolvendo soluções em tecnologia de qualidade e ao mesmo tempo manter a representatividade no Movimento Empresa Júnior.</Texto5>
                </Card1>
                <Card1 >
                    <Icone1>
                        <ImgIcn1 src={ImgValores}/>
                        <NomeCard>Valores</NomeCard>
                    </Icone1>
                    <Texto5>
                        <ul>
                            <li>Profissionalismo</li>
                            <li>Sentimento de dono</li>
                            <li>Orgulho de ser Atlas</li>
                            <li>Coragem</li>
                            <li>Simplicidade</li>
                            <li> Nossos membros nos tornam grandes</li>
                        </ul>
                    </Texto5>
                </Card1>
            </BodyCards>
        </Body>
    )
}



export default CardsNós;