import React from "react";
    import Inicial from "./pages/Inicial/Inicial";
    import Serviços from "./pages/Serviços/Serviços";
    import Nós from "./pages/Nós/Nós";
    import Blog from "./pages/Blog/Blog";
    import Ps from "./pages/Ps/Ps";
  
    import { BrowserRouter as Router, Switch, Route, } from "react-router-dom";

    function App() {
      return (
        <Router>
            <Switch>
              <Route exact path="/" component={Inicial } />
              <Route exact path="/servicos" component={Serviços} />
              <Route exact path="/nos" component={Nós} />
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/ps" component={Ps} />
            </Switch>
        </Router>
      );
    }

    export default App;