import styled from 'styled-components';

export const DivBody = styled.div`
  display: flex;
  width: 100vw;
  height: 700px;
  z-index:999;
  flex-direction: column;
  justify-content: space-around;
  background-color: #363636;
  margin-bottom: 100px;
`

export const TitleHeaderDiv = styled.div`
display: flex;
width: 100%;
justify-content: center;
`
export const HeaderTitle=styled.text`
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
font-style: normal;
font-weight: bold;
font-size: 56px;
line-height: 78px;
letter-spacing: 0.2px;
color: White;
text-align: center;
`
export const CountDownTitle=styled.text`
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
font-style: normal;
font-weight: bold;
font-size: 40px;
line-height: 78px;
letter-spacing: 0.2px;
color: white;
text-align: center;
`
export const CountDownText=styled.text`
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 78px;
letter-spacing: 0.2px;
color: #FAD243;
text-align: center;
`

export const TextYellow=styled.text`
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
font-style: normal;
font-weight: bold;
font-size: 56px;
line-height: 78px;
letter-spacing: 0.2px;
color: #FAD243;
text-align: center;
`
export const CountDownBody=styled.div`
width: 100vw;
display: flex;
height: 380px;
justify-content: center;

`
export const CountDownDiv = styled.div`
display: flex;
width: 50%;
background-color: #1C1C1C;
justify-content: center;
flex-direction: column;
`

export const ButtonsDiv = styled.div`
display: flex;
width: 100%;
height: 70px;
justify-content: center;
`

export const Btn = styled.button`
margin-right: 60px;
font-size: 16px;
border: none;
border-radius: 20px;
padding: 10px 40px 10px 40px;
background-color: #FAD243;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
font-weight: 600;
cursor: pointer;
:hover{
  transform: scale(1.1);
    transition: all 0.2s;
    font-weight: 600;
}

`
export const LinkC = styled.a`
text-decoration: none;
color: black;
`