import React from "react"
import Unifei from "../../img/unifei.png"
import Nuva from "../../img/nuva.png"
import Fejemg from "../../img/fejemg.jpg"
import Bj from "../../img/bj.png"
import { Body,Texto1,Texto2,UnifeiLogo,NuvaLogo,FejemgLogo,BjLogo,BodyImgs,BodyTxt } from "./ParceirosStyled"


const Parceiros = () => {
    return(
    <Body>
        <BodyTxt>
            <Texto1>APOIADORES</Texto1>
            <Texto2>Somos acompanhados por <font color="#FAD243">grandes pessoas</font> </Texto2>
        </BodyTxt>    
        <BodyImgs>
            <UnifeiLogo src={Unifei}/>
            <NuvaLogo src={Nuva}/>
            <FejemgLogo src={Fejemg}/>
            <BjLogo src={Bj}/>
        </BodyImgs>
    </Body>
     )}






export default Parceiros