import React from "react";
import ImgA from "../../img/ftatlas.jpg";
import {BodyEquipe,BodyLeft,BodyRight,Texto1,Texto2,Titulo,Img} from './EquipeStyled'

const Equipe = () => {
    return (
        <div>
            <BodyEquipe>
                <BodyLeft>
                    <Titulo>NOSSA EQUIPE</Titulo>
                    <Texto1>Unidos por uma paixão em comum</Texto1>
                    <Texto2>Nossa equipe é composta por jovens estudantes de engenharia que buscam aprimorar suas capacidades profissionais além das salas de aula. A experiência adquirida em uma empresa júnior prepara os associados para os desafios a serem vencidos em uma empresa sênior.</Texto2>
                </BodyLeft>
                <BodyRight>
                    <Img src={ImgA}/>
                </BodyRight>

            </BodyEquipe>
        </div >
    )
}



export default Equipe