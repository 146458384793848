import styled from "styled-components";

export const Body = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: 100%;
height: 670px;
@media screen and (max-width: 506px){
width: 100%;
height: 868px;
flex-direction: column;
text-align: center;
}

@media screen and (max-width: 1506px){
  width: 100%;
  height: 1500px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
    margin-top: 750px;
  }
`

export const BodyTxt = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
flex-wrap: wrap;
height: 200px;

@media screen and (max-width: 506px){
width: 100%;
height: 235px;
align-items: center;
}
`
export const TituloB = styled.div`
color:#FAD243 ;
font-size: 18px;
font-family: Josefin Sans, Arial ;
font-weight: 500;

@media screen and (max-width:506px){
  left: 42px;
  top: 2620px;
}

@media screen and (max-width: 1506px){
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
`
export const Texto8 = styled.div`
color: black;
font-size: 46px;
font-family: Josefin Sans, Arial;
font-weight: bold;
line-height: 65px;
width: 600px;
@media screen and (max-width:506px){
  left: 42px;
  top: 2650px;
  padding-right: 00px;
  font-weight: 700;
  font-size: 36px;
  line-height: 65px;
  width: 450px;
}
`

export const Texto11 = styled.div`
color: #636363;
font-size: 24px;
width: 500px;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
line-height: 38px;
  @media screen and (max-width:506px){
  left: 42px;
  top: 2720px;
  padding-right: 00px;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  width: 450px;
}

@media screen and (max-width: 1506px){
  margin-left: 50px;
}
`

export const AlinhamentoCarrossel = styled.div`

display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
flex-wrap: wrap;
@media screen and (max-width:506px){

 
  align-items: flex-end;
  width: 100%;
}

@media screen and (max-width: 1506px){
  margin-top: 0px;
}
`