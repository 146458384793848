import React from "react"
import ImgMp from "../../img/mp.svg";
import ImgCompetencia from "../../img/competencia.svg";
import ImgSegurança from "../../img/seguranca.svg";
import { Body,BodyCards,CardA,ImgIcn,Icone4,Texto11,Texto12,Texto13,Texto8,TituloB,BodyTxt } from "./PqEscolherAtlasStyled";

const PqEscolherAtlas = () => {

    return(
        <Body>
            <BodyCards>
                <CardA>
                    <Icone4>
                        <ImgIcn src={ImgMp}/>
                    </Icone4>
                    <Texto12>Melhores Preços</Texto12>
                    <Texto13>Projetos de qualidade com preços abaixo do mercado. Não recebemos salário e somos isentos de certos impostos.</Texto13>
                </CardA>
                <CardA>
                    <Icone4>
                        <ImgIcn src={ImgCompetencia}/>
                    </Icone4>
                    <Texto12>Competência</Texto12>
                    <Texto13>Empresa Júnior considerada por três anos consecutivos uma EJ de Alto Crescimento. Quadruplicamos o faturamento em 2 anos.</Texto13>
                </CardA>
                <CardA>
                    <Icone4>
                        <ImgIcn src={ImgSegurança}/>
                    </Icone4>
                    <Texto12>Segurança</Texto12>
                    <Texto13>Somos todos estudantes da Universidade Federal de Itajubá - Campus Itabira e temos o apoio de professores doutores.</Texto13>
                </CardA>
            </BodyCards>

            <BodyTxt>
                <TituloB>VANTAGENS</TituloB>
                <Texto8>Por que <font color="#FAD243"> escolher a Atlas</font> ?</Texto8>
                <Texto11>Somos uma <b>Empresa Júnior</b> e nossos projetos buscam impactar a vida dos nossos clientes.</Texto11>
            </BodyTxt>
           
        </Body>
    )
}




export default PqEscolherAtlas;