import styled from "styled-components";

export const BostTotal = styled.div`
width: 100%;
height: 616px;
background-color: #fef4d1;
@media screen and (max-width:506px){
  align-content:center;
  justify-content: space-around;
  height: 1166px;
  width: 100%;
}

@media screen and (max-width: 1506px){
  margin-top: -300px;
}
`

export const BodyTextos = styled.div`
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: center;
align-content:flex-end;
width: 100%;
height: 200px;
@media screen and (max-width:600px){
flex-direction: column;
justify-content: space-evenly;}
`
export const Titulo1 = styled.text`
color: #FAD243 ;
font-size: 18px;
font-family: Josefin Sans, Arial;
font-weight: 500;
@media screen and (max-width:600px){
}
`
export const Titulo2 = styled.div`
color: black;
font-size: 46px;
font-family: Josefin Sans, Arial;
font-weight: bold;
@media screen and (max-width:600px){
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  text-align: center;
}
`

export const BodyCirculos = styled.div`
display: flex;
flex-direction: row;
justify-content: space-evenly;
align-items: center;
align-content:flex-end;
width: 100%;
height: 350px;
@media screen and (max-width:600px){
    flex-direction: column;
    height: 900px;
    justify-content: space-evenly;
}
`

export const Circulo1 = styled.div`
width: 240px;
height: 240px;
background-color: white;
border-radius: 50%;
@media screen and (max-width:600px){
}
`
export const Text2 = styled.div`
color: #636363;
font-family: Josefin Sans;
font-style: normal;
font-weight: 600px;
font-size: 24px;
line-height: 24px;
text-transform: uppercase;
text-align: center;

`

export const Circulo2 = styled.div`
width: 240px;
height: 240px;
background-color: white;
border-radius: 50%;
@media screen and (max-width:600px){

}
`
export const Circulo3 = styled.div`
width: 240px;
height: 240px;
background-color: white;
border-radius: 50%;
@media screen and (max-width:600px){
  left: 133px ;
  top: 4700px;
}
`
export const Text = styled.div`
color: #FAD243;
font-size: 46px;
font-family: Josefin Sans, Arial;
font-weight: bold;
line-height: 65px;
text-align: center;
margin-top: 30%;
`