import styled from "styled-components";



export const bodyDiv= styled.div`
    display: flex;
    justify-content: center;
`
export const Title = styled.text`
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
font-style: normal;
font-weight: bold;
font-size: 56px;
line-height: 78px;
letter-spacing: 0.2px;
color: black;
text-align: center;
margin-bottom: 50px;

@media screen and (max-width: 1500px){
    margin-top: 700px;

  }
`
export const TextDiv= styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 25px;

@media screen and (max-width: 1500px){
    

  }
`

export const Wrapper = styled.div`
display: flex;
height: 100vh;
width: 100vw;
justify-content: center;
min-width: 100px;

@media screen and (max-width: 1500px){
    
}

`
export const Accordion1 = styled.div`
width: 1000px;

.item{
    background: #F2F2F2;
    margin-bottom:5px;
    padding: 10px 20px;
    border-radius: 16px;
    margin: 20px 0px;
}
.title{
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Josefin Sans;
    cursor: pointer;
}
.content{
    color: #636363;
    max-height: 0;
    overflow: hidden;
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    transition: all 0.4s cubic-bezier(0,1,0,1);
}
.content.show{
    height: auto;
    max-height: 9999px;
    transition: all 0.4s cubic-bezier(1,0,1,0);
}

@media screen and (max-width: 1500px){
    width: 800px;

  }
`