import React from 'react'
import { Link } from "react-scroll";
import Navbar from '../../components/Navbar/Navbar'
import Img1 from "../../img/img1.png"
import ImgBack from "../../img/background.png"
import Imglogo from "../../img/logoboa.png"

import Rodape from '../../components/Rodape/Rodape';
import Portifolio from '../../components/Portifolio/Porifolio';
import SessãoCarrossel from '../../components/Carrossel/SessãoCarrossel';
import { Menu,Imgstilus,Np,LinkC,Background,Logo,Texto1,TextoInfo,Btn,BtnC,Sm,AlinhamentoPortif,AlinhamentoRodape,AlinhamentoSessãoCar } from './ServiçosStyled';


const Serviços = () => {
    return (
    <div>
        <Menu>
            <Imgstilus src={Img1}/>
            <Logo src={Imglogo}/> 
            <Navbar/>
            <Texto1>Conheça o <font color="#FAD243">nosso portifólio</font></Texto1>
            <TextoInfo>Tire todos seus projetos e ideias do papel, veja que tipo de trabalho nós fazemos!</TextoInfo>
            <Btn><LinkC href="https://calendly.com/contato-atlas/diagnostico" target="_blank">Agende uma conversa</LinkC></Btn>
            <BtnC><LinkC href="https://calendly.com/contato-atlas/diagnostico" target="_blank">Agende</LinkC></BtnC>
            <Sm><Link
                activeClass="active"
                to="at"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                > Saiba mais</Link></Sm>
        </Menu>
        <AlinhamentoPortif>
            <Portifolio></Portifolio>
        </AlinhamentoPortif>
        <Np>
            <Background src={ImgBack}/>
        </Np>
        <AlinhamentoSessãoCar>
             <SessãoCarrossel></SessãoCarrossel>
        </AlinhamentoSessãoCar>
        <AlinhamentoRodape>
            <Rodape></Rodape>
        </AlinhamentoRodape> 
    </div>
    )
}


export default Serviços