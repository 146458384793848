import React from "react"
import { Body,BodyTxt,TituloB,Texto11,Texto8,AlinhamentoCarrossel } from "./SessãoCarrosselStyled"
import Carrossel from "./Carrossel"

const SessãoCarrossel = () => {
    return(
        <Body>
            <BodyTxt>
                <TituloB>PARCEIROS</TituloB>
                <Texto8>Eles escolheram a<font color="#FAD243"> Atlas</font></Texto8>
                <Texto11>Veja o que parceiros acharam do nosso trabalho </Texto11>
            </BodyTxt>
            <AlinhamentoCarrossel>
                <Carrossel></Carrossel>
                </AlinhamentoCarrossel>    
        </Body>
    )
}



export default SessãoCarrossel;