import styled from "styled-components"

export const BodyCards = styled.div`
width: 100%;
height: 562px;
display: flex;
flex-direction: row;
justify-content: space-evenly;
align-items: center;
align-content:flex-end;

@media screen and (max-width: 600px){
  flex-direction: column;
        height: 1500px;
        justify-content: space-evenly;
}

@media screen and (max-width: 1500px){
  flex-direction: column;
        height: 1500px;
        justify-content: space-evenly;
}
`
export const Card1 = styled.div`
width: 362px;
height: 471px;
background-color: #FFFFFF;
border-width: 3px;
border-color: #EBECED;
border-style: solid;
border-radius: 16px;
transition: .7s;
:hover{
  border-color:#FAD243 ;
}
`
export const Icone1 = styled.div`
width: 96px;
height: 96px;
background-color: #FAD243;
margin-top: 40px;
margin-left: 40px;
border-radius: 16px;
`

export const ImgIcn1 = styled.img`
margin-top: 15px;
margin-left: 15px;
width: 70px;
height: 70px;
`
export const Eletrica = styled.h1`
color: black;
font-size: 24px;
font-family: Josefin Sans, Arial ;
margin-top: 40px;
`
export const Texto5 = styled.div`
color: #636363;
font-size: 18px;
font-family: Josefin Sans, Arial ;
margin-top: 70px;
padding-right: 90px;
margin-left: 40px ;
line-height: 25px;
`
export const BtnC1 = styled.button`
margin-left: 40px;
margin-top: 20px;
border: none;
border-radius: 85px;
width: 182px;
height: 52px;
background-color: #FAD243;
font-size: 18px;
font-family: Josefin Sans, Arial ;
cursor: pointer;
:hover{
  transform: scale(1.1);
    transition:  0.2s;
    font-weight: 600;
}
`
export const LinkS = styled.a`
text-decoration: none;
color: black;
`
export const Card2 = styled.div`
width: 362px;
height: 471px;
background-color: #FFFFFF;
border-width: 3px;
border-color: #EBECED;
border-style: solid;
border-radius: 16px;
transition: .7s;
:hover{
  border-color:#FAD243 ;
}
@media screen and (max-width:506px){
  left: 72px ;
  top: 1550px;
}
`
export const Icone2 = styled.div`
width: 96px;
height: 96px;
background-color: #FAD243;
margin-top: 40px;
margin-left: 40px;
border-radius: 16px;
`
export const ImgIcn2 = styled.img`
margin-top: 15px;
margin-left: 15px;
width: 68px;
height: 70px;
`
export const Eletronica = styled.h1`
color: black;
font-size: 24px;
font-family: Josefin Sans, Arial ;
margin-top: 40px;
`
export const Texto6 = styled.div`
color: #636363;
font-size: 18px;
font-family: Josefin Sans, Arial ;
margin-top: 70px;
padding-right: 70px;
margin-left: 40px ;
line-height: 25px;
`
export const BtnC2 = styled.button`
margin-left: 40px;
margin-top: 20px;
border: none;
border-radius: 85px;
width: 182px;
height: 52px;
background-color: #FAD243;
font-size: 18px;
font-family: Josefin Sans, Arial ;
cursor: pointer;
:hover{
  transform: scale(1.1);
    transition:  0.2s;
    font-weight: 600;
}
`
export const Card3 = styled.div`
width: 362px;
height: 471px;
background-color: #FFFFFF;
border-width: 3px;
border-color: #EBECED;
border-style: solid;
border-radius: 16px;
transition: .7s;
:hover{
  border-color:#FAD243 ;
}
@media screen and (max-width:506px){
  left: 72px ;
  top: 2050px;
}
`
export const Icone3 = styled.div`
width: 96px;
height: 96px;
background-color: #FAD243;
margin-top: 40px;
margin-left: 40px;
border-radius: 16px;
`
export const ImgIcn3 = styled.img`
margin-top: 15px;
margin-left: 15px;
width: 69px;
height: 70px;
`
export const Ti = styled.h1`
color: black;
font-size: 24px;
font-family: Josefin Sans, Arial ;

margin-top: 40PX;
`
export const Texto7 = styled.div`
color: #636363;
font-size: 18px;
font-family: Josefin Sans, Arial ;
padding-right: 70px;
margin-left: 40px ;
margin-top: 70px;
line-height: 25px;
`
export const BtnC3 = styled.button`
margin-left: 40px;
margin-top: 20px;
border: none;
border-radius: 85px;
width: 182px;
height: 52px;
background-color: #FAD243;
font-size: 18px;
font-family: Josefin Sans, Arial ;
cursor: pointer;
:hover{
  transform: scale(1.1);
    transition:  0.2s;
    font-weight: 600;
}
`