import React from "react"
import CardsGestao from "../cardGestao/CardsGestao"
import CardsProjeto from "../CardsProjeto/CardsProjeto"
import {Body,Titulo,TituloA,TituloB} from "./FuncionamentoStyled"

const Funcionamento = () => {

    return (

        <Body>
            <Titulo>Como a Atlas funciona?</Titulo>
            <TituloA>Diretorias de Gestão</TituloA>
            <CardsGestao/>
            <TituloB>Diretorias de Projeto</TituloB>
            <CardsProjeto/>
        </Body>
    )
}


export default Funcionamento