import styled from "styled-components"

export const Body= styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`


export const Imgstilus = styled.img`
position: absolute;
display: inline-block;
width: 780px;
height: 670px;
@media screen and (max-width:506px) {
    width: 0%;
    height: 0%;
}
`
export const Logo1 = styled.img`
position: absolute;
top: 20px;
display: inline-block;
left: 80px;
height: 80px;
width: 180px;
@media screen and (max-width: 506px){
  left: 20px ;
  top: 40px;
}
`

export const Texto1 = styled.div`
position: absolute;
top: 190px;
right: 700px;
left: 80px;
font-style: normal;
font-weight: bold;
font-size: 72px;
line-height: 78px;
width: 450px;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
@media screen and (max-width: 506px){
  right: 0px;
  left: 95px;
  font-weight: 700;
  font-size: 56px;
  top: 200px ;
  width: 350px;
  z-index: -1;
}
`
export const TextoInfo = styled.div`
position: absolute;
color: #636363;
left: 80px;
top: 300px;
right: 700px;
font-size: 24px;
font-family: Josefin Sans, Arial, Helvetica, sans-serif;
line-height: 38px;
width: 500px;
@media screen and (max-width: 506px){
    text-align: center;
    left: 5px;
    right: 0;
}
`
export const Btn = styled.button`
position: absolute;
left: 72px;
top: 460px;
border: none;
border-radius: 85px;
padding: 15px 60px 15px 60px;
background-color: #FAD243;
font-family: Josefin Sans, Arial;
font-weight: 600;
font-size: 18px;
cursor: pointer;
:hover{
  transform: scale(1.1);
    transition:  0.2s;
    font-weight: 600;
}
@media screen and (max-width: 506px){
  width: 0%;
  padding: 0px 0px 0px 0px;
  font-size: 0px ;
}
`
export const BtnC = styled.button`
  width: 0%;
  padding: 0px 00px 0px 0px;
  font-size: 0px ;
@media screen and (max-width: 506px){
  width: 155px ;
  height: 52px;
  position: absolute;
  left: 72px;
  top: 500px;
  border: none;
  border-radius: 20px;
  background-color: #FAD243;
  font-family: Josefin Sans, Arial;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  :hover{
    transform: scale(1.1);
    transition:  0.2s;
    font-weight: 600;
}
}
`
export const Sm = styled.div`
position: absolute;
color: #636363;
font-size: 18px;
top: 475px;
left: 475px;
font-family: Josefin Sans, Arial;
font-weight: bold;
width: 100px;
cursor: pointer;
@media screen and (max-width:506px) {
  left: 320px ;
  top: 515px ;
}
`
 export const BaseC = styled.div`
 display: flex;
width: 100%;
min-width: 1348px;
height: 727px;
background-color: black;
opacity: 90%;
 top: 551px;
 position: relative;
 @media screen and (max-width:506px) {
     min-width: 525px;
}
 `
export const Texto2 = styled.text`
 font-style: normal;
 font-weight: bold;
font-size: 55px;
line-height: 78px;
 font-family: Josefin Sans, Arial, Helvetica, sans-serif;
 @media screen and (min-width:1400px){
  
 }
 @media screen and (max-width:506px) {
 
}
`
 export const CardContador = styled.div`
 background-color: black;
 width: 973px;
 height: 313px;
 align-items: center;
 justify-content :center;
 position: relative;
 top: 80px;
 @media screen and (min-width:1400px){
  align-items:center;
 }
 @media screen and (max-width:506px) {
     width: 100%;
     min-width: 500px;
     left: 0px;
 }
 `
 export const Texto3 = styled.div`
 font-family: Josefin Sans, Arial, Helvetica, sans-serif;
 font-style: normal;
 font-weight: bold;
 font-size: 40px;
 line-height: 78px;
 letter-spacing: 0.2px;
 color: #FFF;
left: 280px;
 top:20px;
 @media screen and (max-width:506px) {
     font-size: 30px;
     left: 90px;
}
 `
 export const Texto4 = styled.text`
 position: absolute;
 left: 425px;
 top: 70px;
 font-family: Josefin Sans;
font-style: normal;
 font-weight: 300;
 font-size: 28px;
 line-height: 78px;
 letter-spacing: 0.2px;
color: #FAD243;
 @media screen and (max-width:506px) {
     font-size: 25px;
     left: 190px;
 }
`
export const Countdown1 = styled.div`
 color: white;
 padding-top: 140px;
 padding-left: 120px;
 @media screen and (min-width:1400px){

 }
 @media screen and (max-width: 1000px){
   padding-left: 100px;
   min-width: 1000px;
}
`
 export const Info = styled.a`
 color: white;
 position: absolute;
 padding-left: 152.5px;
 font-family: Josefin Sans;
 font-size: 15px;
 @media screen and (max-width: 506px){
   padding-left: 0px;
   left: 98px;
 }
 `
 export const Info1 = styled.a`
 color: white;
 position: absolute;
 padding-left:350px;
 font-family: Josefin Sans;
 font-size: 15px;
 @media screen and (max-width: 506px){
   padding-left: 0px;
   left: 180px;
 }
 `
 export const Info2 = styled.a`
 color: white;
 padding-left:552.5px;
 font-family: Josefin Sans;
 font-size: 15px;
 @media screen and (max-width: 506px){
   padding-left: 260px;
   position: absolute;

 }
 `
 export const Info3 = styled.a`
 color: white;
 float:right;
 margin-right: 131px;
 padding-left:40px;
 font-family: Josefin Sans;
 font-size: 15px;
 @media screen and (max-width: 506px){
   padding-left: 0px;
   margin-right: 80px;
   ;
 }
 `
 export const BtnI = styled.button`
 position: absolute;
 border: none;
 border-radius: 20px;
 width: 182px;
 height: 52px;
 background-color: #FAD243;
 font-size: 18px;
 font-family: Josefin Sans, Arial ;
 cursor: pointer;
 :hover{
   transform: scale(1.1);
     transition:  0.2s;
     font-weight: 600;
 }

 `
 export const BtnE = styled.button`
 position: absolute;

 border: none;
 border-radius: 20px;
 width: 182px;
 height: 52px;
 background-color: #FAD243;
 font-size: 18px;
 font-family: Josefin Sans, Arial ;
 cursor: pointer;
 :hover{
   transform: scale(1.1);
     transition:  0.2s;
     font-weight: 600;
 }

 `
 export const buttonDiv=styled.div`
 width : 400px;
 height: 100px;
 align-items: center;
 justify-content: space-between;
 ` 
 export const Cronograma = styled.div`
 width: 100%;
 height: 563px;
 background-color: #FFF;
 top: 250px;
 position: relative;
 `
 export const Imgstilus1 = styled.img`
 position: absolute;
 display: inline-block;
 width: 780px;
 height: 563px;
 @media screen and (max-width:506px) {
   width: 0%;
   padding: 0px 0px 0px 0px;
   font-size: 0px ;
 }
 `
 export const TituloC = styled.div`
 position: absolute;
 font-family: Josefin Sans, Arial, Helvetica, sans-serif;
 font-style: normal;
 font-weight: bold;
 font-size: 56px;
 line-height: 78px;
 letter-spacing: 0.2px;
 color: black;
 left: 520px;
 top: 50px;
 @media screen and (min-width:1400px){
   left: 600px;
 }
 @media screen and (max-width:506px) {
     font-size: 50px;
     left: 90px;
     top: 30px;
 }
 `

 export const ListaCronogrma = styled.div`
 position: relative;
 font-family: Josefin Sans;
 font-style: normal;
 font-weight: 600;
 font-size: 24px;
 line-height: 28px;
 left: 80px;
 top: 180px;
 margin-right: 140px;
 width: 1130px;
 @media screen and (min-width:1400px){
   left: 160px;
 }
 @media screen and (max-width:506px) {
   width: 0%;
   padding: 0px 0px 0px 0px;
   font-size: 0px ;
 }
 `
 export const ListaCronogramaC = styled.div`
 @media screen and (max-width:506px) {
 position: relative;
 font-family: Josefin Sans;
 font-style: normal;
 font-weight: 600;
 font-size: 24px;
 line-height: 28px;
 left: -20px;
 top: 110px;
 line-height: 50px;
 width: 520px;
 }
 `
export const TituloFaq = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
font-family: Josefin Sans;
font-style: normal;
font-weight: bold;
font-size: 72px;
line-height: 78px;
margin-top: 150px;
@media screen and (max-width:506px) {
    font-size: 72px;
    left: 170px;
    margin-top: 780px;
}
`
export const Alinhamento1 = styled.div`
position: absolute;
padding-top: 0px;
@media screen and (max-width:506px) {
    padding-top: 50px;
  width: 506px;
}
`

export const AlinhamentoRodape = styled.div`
top: 900px;
position: relative;
@media screen and (min-width:1400px){
}
@media screen and (max-width:506px){
  top: 1000px;
  width: 506px;
}
`
export const LinkC = styled.a`
text-decoration: none;
color: black;
`
export const AlinhamentoFun = styled.div`
margin-top: 650px;
`