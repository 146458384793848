import { Header } from "./Header";
import {Btn,BtnC,Imgstilus,Menu1,Sm,Texto1,Texto2,TextoInfo,LinkC, Link,ContainerButton, ContainerText} from './GenericHeaderStyled';

import Img1 from "../../img/img1.png";

export function GenericHeader({BlackTitle, YellowTitle, Text, ButtonTitle, linkYellowButton, LinkSaibaMais}){

    return(
        <>
        <Imgstilus src={Img1}/>
        <Header/>
        <Menu1>
        <ContainerText>
        <Texto1>{BlackTitle}&nbsp;</Texto1>
        <Texto2> {YellowTitle}!</Texto2>
        </ContainerText> 
        <div>
        <TextoInfo>{Text}</TextoInfo>
        </div> 
        <ContainerButton>
        <Btn><LinkC href={linkYellowButton} target="_blank">{ButtonTitle}</LinkC></Btn>   
         <Sm><Link
                href={LinkSaibaMais}
                target="_blank"
                activeClass="active"
                to="at"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                > Saiba mais</Link></Sm>      
        </ContainerButton>   
      </Menu1>
        </>
    );
}