import React from "react";

import Faq from "../../components/Faq/Faq";

import Funcionamento from "../../components/Funcionamento/Funcionamento";
import Rodape from "../../components/Rodape/Rodape";
import { Cronograma } from "../../components/Cronograma/Cronograma";
import { Body } from "./PsStyled";
import { GenericHeader } from "../../components/Header/GenericHeader";
import { CountDown } from "../../components/countDown/Countdown";
const Ps = () => {
  return (
    <Body>
    <GenericHeader
    BlackTitle='Seja um'
    YellowTitle="Titã"
    Text='Aguarde a sua chance de ser um Titã. Em breve, novas notícias sobre o Processo Seletivo.'
    ButtonTitle='Faça a sua inscrição'
    linkYellowButton='https://docs.google.com/forms/d/e/1FAIpQLScfl98np4ZmhH5plBLPwAgqE2t-LSkqj0qPu-msEk06ExDkkw/viewform'
    LinkSaibaMais='https://drive.google.com/file/d/1RJT98-x31EttEjqj45Ec6MAyYj-3DcgB/view?usp=sharing'
    
    />
    <CountDown/>
    <Cronograma/>
    <Funcionamento />
    <Faq/>
    <Rodape/>
    </Body>
  )
}

export default Ps;