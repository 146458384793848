import React from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import {BostTotal,BodyTextos,BodyCirculos,Text,Text2,Titulo1,Titulo2,Circulo1,Circulo2,Circulo3} from "./DadosStyled"
const Dados = () => {
  
    return (
<div>
    <BostTotal>
    <BodyTextos>
        <Titulo1>EM NÚMEROS</Titulo1>
        <Titulo2>A tradução do que<font color="#FAD243"> nós já fizemos</font> </Titulo2>
    </BodyTextos>
    <BodyCirculos>
        <Circulo1>
            <Text>
          <VisibilitySensor partialVisibility offset={{ bottom: 20 }}>
        {({ isVisible }) => (
          <div style={{ height: 100 }}>
            {isVisible ? <CountUp end={38} duration={2} /> : null}
          </div>
        )}
      </VisibilitySensor>
            </Text>
            <Text2>PROJETOS</Text2> 
        </Circulo1>
        <Circulo2>
        <Text>
          <VisibilitySensor partialVisibility offset={{ bottom: 20 }}>
        {({ isVisible }) => (
          <div style={{ height: 100 }}>
            {isVisible ? <CountUp end={93} duration={2} /> : null}
          </div>
        )}
      </VisibilitySensor>
            </Text>
            <Text2>MILHARES FATURADOS</Text2>
        </Circulo2>
        <Circulo3>
        <Text>
          <VisibilitySensor partialVisibility offset={{ bottom: 20 }}>
        {({ isVisible }) => (
          <div style={{ height: 100 }}>
            {isVisible ? <CountUp end={11} duration={2} /> : null}
          </div>
        )}
      </VisibilitySensor>
            </Text>
            <Text2>PROJETOS DE IMPACTO</Text2>
        </Circulo3>
    </BodyCirculos>
    </BostTotal>
</div>
    )}



export default Dados