import React, { useState }from 'react'
import { Nav,Alinhamento1,Hamburger,Menu,MenuLink,Agendar,LinkC} from './NavbarStyled';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Nav>
        <Alinhamento1>
        <Hamburger onClick={() => setIsOpen(!isOpen)}>
            <span />
            <span />
            <span />
        </Hamburger>
        </Alinhamento1>
        <Menu isOpen = {isOpen}>
            <MenuLink href="/">Início</MenuLink>
            <MenuLink href="/servicos">Serviços</MenuLink>
            <MenuLink href="/nos">Nós</MenuLink>
            <MenuLink href="/ps">Seja um Titã</MenuLink>
        </Menu>
        <Agendar><LinkC href="https://calendly.com/contato-atlas/diagnostico" target="_blank">Agendar</LinkC></Agendar>
    </Nav>
  )
}




export default Navbar